import { StyleSheet } from "react-native";

const UserMainStyles = StyleSheet.create({
  logo: {
    width: 75,
    height: 20,
    resizeMode: "contain",
  }
});

export default UserMainStyles;