import { VStack, Text, FlatList, Box } from "native-base";
import React from "react";

const Notification = () => {
  const data = [
    {
      name: "Garbage Collect",
      date: "15/07/2021",
      status: "Completed",
    },
    {
      name: "Garbage Collect",
      date: "14/07/2021",
      status: "Calling Success",
    },
    {
      name: "Garbage Collect",
      date: "08/07/2021",
      status: "Completed",
    },
    {
      name: "Garbage Collect",
      date: "07/07/2021",
      status: "Calling Success",
    },
  ];
  return (
    <>
      <Box backgroundColor="#445AD8" p={5}>
        <Text fontSize="2xl" color="white" bold>
          {" "}
          Notifications
        </Text>
      </Box>
      <FlatList
        data={data}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item }) => (
          <Box>
            <Text bold fontSize="md" pl={6} py={1}>
              {item.name}
            </Text>
            <Text fontSize="xs" pl={6}>
              {item.date}
            </Text>
            <Text
              bold
              fontSize="sm"
              pl={6}
              py={3}
              borderBottomWidth={1}
              color={item.status === "Completed" ? "#42d934" : "#f7c719"}
            >
              {item.status}
            </Text>
          </Box>
        )}
      ></FlatList>
    </>
  );
};

export default Notification;
