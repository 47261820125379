import React, { useEffect, useState, useRef } from "react"
import {
  ImageBackground,
  StatusBar,
  Alert,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  BackHandler,
  Platform,
} from "react-native"
import {
  NativeBaseProvider,
  View,
  Text,
  useToast,
  Button,
  Center,
  Box,
  VStack,
  HStack,
  Input
} from "native-base"
import LoadingOverlay from "./components/LoadingOverlay"
import { Provider } from "react-redux"
import firebase from "firebase/app"
import { NavigationContainer, DefaultTheme } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import * as MediaLibrary from "expo-media-library"
import Moment from "moment"
import base64 from 'react-native-base64'

const firebaseConfig = {
  apiKey: "AIzaSyCbaFxeNpUI-KHN0-6PQyXPQyWaRRlAvmc",
  authDomain: "i-collect-sws.firebaseapp.com",
  projectId: "i-collect-sws",
  storageBucket: "i-collect-sws.appspot.com",
  messagingSenderId: "420679674481",
  appId: "1:420679674481:web:ad434442ab2cda2d2c49d0"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

import LoginScreen from "./container/LoginScreen"
import MainScreen from "./container/MainScreen"
import RegisterScreen from "./container/RegisterScreen"
import ForgetPassword from "./container/ForgetPassword"
import stores from "./store/config"
import { PersistGate } from "redux-persist/integration/react"
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration"
import * as Notifications from "expo-notifications"
import { WebView } from "react-native-webview"

const deviceHeight = Dimensions.get("window").height
const deviceWidth = Dimensions.get("window").width
const backgroundImage = require("./assets/device_background.png")
const { store, persistor } = stores()

const AppStack = createStackNavigator()
const paymentLogo = require("./assets/MPSPpay.png")

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "grey",
  },
  webView: {
    height: deviceHeight,
    width: deviceWidth,
  },
  tabBarContainer: {
    padding: 20,
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: "#b43757",
  },
  button: {
    color: "white",
    fontSize: 24,
  },
})

const NavigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "transparent",
  },
}

const config = {
  animation: "spring",
  config: {
    stiffness: 1000,
    damping: 50,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true,
  }),
})

const RedirectPage = (props) => {
  const toast = useToast();
  const [isLoading, setLoading] = useState(false)
  const [topUpCall, setTopUpCall] = useState(false)

  const diasbleBack = () => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener("hardwareBackPress", () => {
        return true
      })
    }
  }

  const enableBack = () => {
    if (Platform.OS === "android") {
      BackHandler.removeEventListener("hardwareBackPress", () => {
        return true
      })
    }
  }

  const backButtonHandler = () => {
    Platform.OS === "android"
      ? Alert.alert(
          "Batal Transaksi",
          "Tekan OK untuk membatalkan transaksi. Tekan Cancel untuk menyambung tambah nilai.",
          [
            {
              text: "Cancel",
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel",
            },
            {
              text: "OK",
              onPress: () => {
                props.navigation.navigate("main")
                toast.show({
                  title: "Tambah nilai dibatalkan.",
                  placement: "bottom",
                  status: "danger",
                })
              },
            },
          ],
          { cancelable: false }
        )
      : BinaryAlert(
          "Batal Transaksi",
          "Tekan OK untuk membatalkan transaksi. Tekan Cancel untuk menyambung tambah nilai.",
          () => {
            props.navigation.navigate("main")
            toast.show({
              title: "Tambah nilai dibatalkan.",
              placement: "bottom",
              status: "danger",
            })
          },
          () => console.log("Cancel Pressed")
        )
  }

  const handleTopUpPWA = ( dataToSubmit ) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(dataToSubmit),
    }
    fetch(
      "https://asia-southeast1-i-collect-sws.cloudfunctions.net/topUpEventPWA",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data == "success") {
          console.log('success')
          handleTopUp( dataToSubmit.amount )
        } else {
          console.log('fail')
        }
      })
  }

  
const handleTopUp = () => {
  console.log('topup')
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify({
      name: 'Top Up',
      transaction_amount: Number(props.route.params.topUpValue),
      date: Moment(new Date()).format( 'MMM DD' ),
      time: Moment(new Date()).format( 'hh:mm A' ),
      ic_num: props.route.params.ic_num,
    }),
  }
  fetch(
    "https://asia-southeast1-i-collect-sws.cloudfunctions.net/topUpEvent",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      setTopUpCall(false)
      if (data == "success") {
        enableBack()
        setLoading(false)
        props.navigation.navigate("home", {topUpStatus: 'success'})
        toast.show({
          title: 'Tambah Nilai Berjaya',
          placement: "bottom",
          status: "success",
        })
      } else {
        handleTopUp()
      }
    })
}

  return (
    <>
      <Button onPress={ () => backButtonHandler()}>Back</Button>
      {Platform.OS === "android" ?
    <View style={styles.container}>
      { isLoading && <LoadingOverlay/>}
      <WebView
        source={{html: props.route.params.redirect }}
        onNavigationStateChange={(webViewState)=>{
          if ( webViewState.url.includes('https://asia-southeast1-i-collect-sws.cloudfunctions.net/topUpEventMobile') && !topUpCall){
            setTopUpCall(true)
            setLoading(true)
            console.log(webViewState.url)
            let pathname = webViewState.url
            if( pathname.includes('datareturn') ){
              pathname = pathname.substring(pathname.indexOf('=')+1, pathname.length)
              let datareturn=JSON.parse(base64.decode(pathname))
              if( datareturn.status === "SUCCESSFUL" ){
                handleTopUpPWA(datareturn)
              } else {
                props.navigation.navigate("home", {topUpStatus: 'fail'})
                toast.show({
                  title: 'Tambah Nilai Gagal',
                  placement: "bottom",
                  status: "danger",
                })
              }
            }else{
              console.log('nodatareturn')
            }
          }
          diasbleBack()
        }}
        style={ styles.webView }
        javaScriptEnabled={true}
        domStorageEnabled={true}
        startInLoadingState={false}
        scalesPageToFit={true}
      />
          </View>
      : (
        <>
          { isLoading && <LoadingOverlay/>}
          <form
            id="gateway"
            method="post"
            action="https://mbsppay.mbsp.gov.my/ws/gateway_checkout.php"
            onSubmit={()=>setLoading(true)}
          >
            <input
              type="hidden"
              name="batch_id"
              value={props.route.params.batch_id}
            />
            <Center h="90vh">
              <Box bg="white" borderRadius="30px" p="30px">
                <VStack space={5}>
                  <Input InputLeftElement={<Text w="90px" px="10px">IC No.</Text>} isDisabled value={props.route.params.ic_num}/>
                  <Input InputLeftElement={<Text w="90px" px="10px">Amount.</Text>} isDisabled value={'RM ' + props.route.params.topUpValue.toFixed(2)}/>
                  <input style={{padding:"15px", backgroundColor:"#06b6d4", color:"white", boxShadow:"none", borderRadius:"10px", border:"none", cursor:"pointer", ":active":{color:"#0d5cdb"}}} type="submit" value="CONFIRM!" />
                </VStack>
              </Box>
            </Center>
          </form>
        </>
      )}
    </>
  )
}

const BinaryAlert = (
  title,
  desc,
  onPositivePress = () => {},
  onNegativePress = () => {}
) => {
  const res = window.confirm(`${title}\n${desc}`)
  if (res) onPositivePress()
  else onNegativePress()
}

const App = () => {
  const [hasStoragePermission, setStoragePermission] = useState(null)
  const notificationListener = useRef()
  const responseListener = useRef()

  useEffect(() => {
    StatusBar.setHidden(true)
    StatusBar.setTranslucent(true)
    ;(async () => {
      const { status } = await MediaLibrary.requestPermissionsAsync()
      setStoragePermission(status === "granted")
    })()

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        notificationHandler(
          notification.request.content.title,
          notification.request.content.body
        )
      })

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log(response)
      })
  }, [])

  const notificationHandler = (title, substitle) => {
    Alert.alert(
      title,
      substitle + " Muat halaman semula untuk memeriksa pengutipan.",
      [{ text: "OK" }],
      { cancelable: false }
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingOverlay />} persistor={persistor}>
        <NativeBaseProvider>
          <ImageBackground
            source={backgroundImage}
            resizeMode="cover"
            style={{ flex: 1, height:Platform.OS==="web"?"100vh":"100%", width:Platform.OS==="web"?"100vw":"100%" }}
          >
            <NavigationContainer theme={NavigationTheme}>
              <AppStack.Navigator
                screenOptions={{
                  gestureEnabled: true,
                  gestureDirection: "horizontal",
                  transitionSpec: {
                    open: config,
                    close: config,
                  },
                }}
                animation="fade"
              >
                <AppStack.Screen
                  name="login"
                  component={LoginScreen}
                  options={{ headerShown: false }}
                />
                <AppStack.Screen
                  name="main"
                  component={MainScreen}
                  options={{ headerShown: false }}
                />
                <AppStack.Screen
                  name="register"
                  component={RegisterScreen}
                  options={{ headerShown: false }}
                />
                <AppStack.Screen
                  name="forget"
                  component={ForgetPassword}
                  options={{ headerShown: false }}
                />
                <AppStack.Screen
                  name="redirect"
                  component={RedirectPage}
                  options={{ headerShown: false }}
                />
              </AppStack.Navigator>
            </NavigationContainer>
          </ImageBackground>
        </NativeBaseProvider>
      </PersistGate>
    </Provider>
  )
}

export default App

serviceWorkerRegistration.register()
