import React from "react";

import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import EnterEmail from "./EnterEmail";
import EnterCode from "./EnterCode";
import EnterNewPassword from "./EnterNewPassword";

import TopNav from "../../components/TopNav";

const Stack = createStackNavigator();

const config = {
  animation: "spring",
  config: {
    stiffness: 1000,
    damping: 50,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};

const ForgetPassword = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        gestureEnabled: true,
        gestureDirection: "horizontal",
        transitionSpec: {
          open: config,
          close: config,
        },
      }}
      animation="fade"
    >
      <Stack.Screen
        name="email"
        component={EnterEmail}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="code"
        component={EnterCode}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="password"
        component={EnterNewPassword}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default ForgetPassword;
