import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import RegisterForm from "./RegisterForm";
import RegisterIC from "./RegisterIC";

const Stack = createStackNavigator();

const registerIC = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="register"
        component={RegisterIC}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="form"
        component={RegisterForm}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  );
};

export default registerIC;
