import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Input,
  Text,
  VStack,
} from "native-base";
import Moment from 'moment'
import React, { useState, useEffect, useRef } from "react";
import { Animated, Platform } from "react-native";
import { connect } from "react-redux";
import * as Crypto from 'expo-crypto';
import base64 from 'react-native-base64'
import TopNav from "../../../components/TopNav";
import LoadingOverlay from "../../../components/LoadingOverlay"

const checkValue = (data) => {
  let value = parseInt(data);
  if (value < 10) return false;
  else return true;
};

const TopUp = (props) => {
  const [reloadValue, setReloadValue] = useState("");
  const [ user, setUser ] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState("");
  const [buttonActive, setButtonActive] = useState(false);
  const secretkey='59f65442bffd9951655678a052a0e35f0cbf8caf'
  const dateTimeString = Moment(new Date()).format('YYYYMMDD')
  const running_no = Math.floor(Math.random()* 60000000).toString().substring( 2, 4 )
  const batch_id = 'IC' + dateTimeString + running_no
  const kod_hasil = 'H0272510'
  const visibility = useRef(new Animated.Value(0)).current;
  const digest = Crypto.digestStringAsync(
    Crypto.CryptoDigestAlgorithm.SHA1,
    batch_id+reloadValue+secretkey
  );

  useEffect( () => {
    setUser(props.user)
  }, [])

  useEffect(() => {
    setReloadValue(reloadValue.replace(/[^0-9]/g, ""));
    if (reloadValue != "")
      if (!checkValue(reloadValue)) {
        setError("Jumlah tambah nilai tidak boelh kurang daripada RM10");
        setButtonActive(false);
      } else 
      {
        setError("");
        setButtonActive(true);
      }
    else setButtonActive(false);
  }, [reloadValue]);

  const makePayment = () => {
    setLoading(true)
    const dateString = Moment(new Date()).format('YYYYMM')
    const datajson = base64.encode(JSON.stringify([
      {
        'no_akaun': 'IC'+ dateString + user.ic_num,
        'keterangan': 'Icollect Top Up',
        'amaun': reloadValue,
        'nama': props.user.name
      }
    ]))
    digest.then((res) =>{
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          'batch_id': batch_id,
          'total_amount': reloadValue,
          'secretkey': '59f65442bffd9951655678a052a0e35f0cbf8caf',
          'datajson': datajson,
          'hash': res,
          'email': props.user.email,
          'callback_url': Platform.OS !== 'android' && Platform.OS !== 'ios' 
          ? 'https://testpwa-version1.firebaseapp.com/' 
          : 'https://asia-southeast1-i-collect-sws.cloudfunctions.net/topUpEventMobile',
          'return_url':Platform.OS !== 'android' && Platform.OS !== 'ios' 
          ? 'https://testpwa-version1.firebaseapp.com/'
          : 'https://asia-southeast1-i-collect-sws.cloudfunctions.net/topUpEventMobile'
        })
      }
      console.log(requestOptions)
      fetch(
        "https://mbsppay.mbsp.gov.my/ws/gateway.php",
        requestOptions
      )
      .then((response) => response.text())
      .then((data) =>{   
        setLoading(false)
        console.log(data)
        props.navigation.navigate("redirect", {redirect: data, topUpValue: reloadValue, ic_num: props.user.ic_num, batch_id: batch_id })
      })
    })
  }

  return (
    <>
      <TopNav rightComponent="notification" navigation={props.navigation} />
      {isLoading && <LoadingOverlay />}
      <Center
        width={Platform.OS === "web" ? "100vw" : '100%'}
        height={Platform.OS === "web" ? "90vh" : '90%'}
        bottom={10}
      >
        <Box backgroundColor="white" p={5} borderRadius={20} w="100%">
          <VStack space={3}>
            <Text bold fontSize="2xl">
              Tambah Nilai
            </Text>
            <FormControl>
              <Input
                value={reloadValue}
                onChangeText={(val) => setReloadValue(val)}
                placeholder="Jumlah tambah nilai"
                InputLeftElement={
                  <Text bold fontSize="xl" p={2} color="royalblue">
                    RM
                  </Text>
                }
              />
              {error === "" ? (
                <FormControl.HelperText>
                  Jumlah minima adalah RM10
                </FormControl.HelperText>
              ) : (
                <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>
              )}
            </FormControl>
            <HStack space={2}>
              <Button
                flex={1}
                borderRadius={30}
                variant="outline"
                onPress={() => setReloadValue("10")}
              >
                RM10
              </Button>
              <Button
                flex={1}
                borderRadius={30}
                variant="outline"
                onPress={() => setReloadValue("50")}
              >
                RM50
              </Button>
              <Button
                flex={1}
                borderRadius={30}
                variant="outline"
                onPress={() => setReloadValue("100")}
              >
                RM100
              </Button>
            </HStack>
            <Button borderRadius={30} isDisabled={!buttonActive} 
              onPress={()=> makePayment()}>
              Tambah
            </Button>
          </VStack>
        </Box>
      </Center>
      {/* <TabMenu navigation={props.navigation} activePage={props.navigation.isFocused?"topUp":""}/> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
  transactions: state.userReducer.events,
});

const mapDispatchToProps = (dispatch) => ({
  actions: (val) => {
    dispatch(storeUser(val));
  },
  saveCred: (val) => {
    dispatch(storeLogin(val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TopUp);
