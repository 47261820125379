import { StyleSheet } from "react-native";

const RegisterStyles = StyleSheet.create({
  formContainer:{
    backgroundColor:"white",
    margin:"5%",
    borderRadius:20
  }
});

export default RegisterStyles;