import React from "react";
import { Platform, ActivityIndicator, View, Text } from "react-native";

const LoadingOverlay = ({ message }) => {
  return (
    <View
    style={{
      width:Platform.OS === "web" ? "100vw" : "100%",
      height:Platform.OS === "web" ? "100vh" : '100%',
      zIndex: 999,
      position: "absolute",
      bottom:0,
      backgroundColor: "black",
      opacity:0.5,
      justifyContent: "center"
    }}
      
    >
      <ActivityIndicator size="large" color="white"/>
    </View>
  );
};

export default LoadingOverlay;
