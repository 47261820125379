import React from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { connect } from "react-redux";
import { WebView } from "react-native-webview";

import HomeScreen from "./HomeScreen";
import TransactionHistory from "./TransactionHistory";
import TopUp from "./TopUp";
import ShowQR from "./ShowQR";
import UserProfile from "./UserProfile";
import ChangePassword from "./ChangePassword";

import Notification from "./Notifications";
import TabMenu from "../../components/TabMenu";

const Tab = createBottomTabNavigator();
const ProfileStack = createStackNavigator();
const MainStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const config = {
  animation: "spring",
  config: {
    stiffness: 1000,
    damping: 50,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};

const ProfileStackScreen = () => {
  return (
    <ProfileStack.Navigator
      screenOptions={{
        gestureEnabled: true,
        gestureDirection: "horizontal",
        transitionSpec: {
          open: config,
          close: config,
        },
      }}
      animation="fade"
    >
      <ProfileStack.Screen
        name="profile"
        component={UserProfile}
        options={{ headerShown: false }}
      />
      <ProfileStack.Screen
        name="changePassword"
        component={ChangePassword}
        options={{ headerShown: false }}
      />
    </ProfileStack.Navigator>
  );
};

const MainTabScreen = () => {
  return (
    <>
      <MainStack.Navigator
        screenOptions={{
          gestureEnabled: true,
          gestureDirection: "horizontal",
          transitionSpec: {
            open: config,
            close: config,
          },
        }}
        animation="fade"
      >
        <MainStack.Screen
          name="home"
          component={HomeScreen}
          options={{ headerShown: false }}
        />
        <MainStack.Screen
          name="history"
          component={TransactionHistory}
          options={{ headerShown: false }}
        />
        <MainStack.Screen
          name="topUp"
          component={TopUp}
          options={{ headerShown: false }}
        />
        <MainStack.Screen
          name="showQR"
          component={ShowQR}
          options={{ headerShown: false }}
        />
        <MainStack.Screen
          name="profile"
          component={ProfileStackScreen}
          options={{ headerShown: false }}
        />
      </MainStack.Navigator>
      <TabMenu />
    </>
  );
};

const MainScreen = (props) => {
  return (
    <>
      <Drawer.Navigator drawerContent={(props) => <Notification {...props} />}>
        <Drawer.Screen name="main" component={MainTabScreen} />
      </Drawer.Navigator>
    </>
  );
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.MenuReducer,
});

export default connect(mapStateToProps)(MainScreen);
