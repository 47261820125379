import React from "react"
import { Modal, Button, useToast } from "native-base"
import { connect } from "react-redux";

const OrderModal = (props) => {
  const toast = useToast()
  return (
    <Modal isOpen={props.showModal} onClose={() => props.setShowModal(false)}>
      <Modal.Content maxWidth="400px">
        <Modal.CloseButton />
        <Modal.Header>Pesan Pemungut</Modal.Header>
        <Modal.Body>
          Adakah anda ingin memesan pemungut untuk mengutip sampah di kedai
          anda?
        </Modal.Body>
        <Modal.Footer>
          <Button.Group variant="ghost" space={2}>
            <Button
              onPress={() => {
                toast.show({
                  title: "Pesanan anda telah berjaya dihantarkan",
                })
                props.setShowModal(false)
                // POST here for new event created, date format pls check xia ur database can use ma
                // Think how update the Notification, will be using push after this order as ur database 
                // will not update so fast ma Hor, but i rmb can wait it done then call it to refresh 
                // the user data de?
                let timeStamp = new Date()
                let time = String(timeStamp.getHours()).padStart(2, '0') + ":" + String(timeStamp.getMinutes()).padStart(2, '0')
                let date = String(timeStamp.getDate()).padStart(2,'0') + "/" + String(timeStamp.getMonth()+1).padStart(2, '0') + "/" + timeStamp.getFullYear()
              }}
            >
              YA
            </Button>
            <Button
              onPress={() => {
                props.setShowModal(false);
              }}
            >
              TIDAK
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  user: state.userReducer.users
});

const mapDispatchToProps = (dispatch) => ({
  actions: (val) => {
    dispatch(storeUser(val));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderModal);
