import React, { useState, useEffect } from "react"
import {
  ScrollView,
  Box,
  VStack,
  Icon,
  Button,
  Modal,
  Text,
  Input,
  useToast,
  Select,
  FormControl,
  IconButton,
} from "native-base"
import { MaterialIcons } from "@expo/vector-icons"
import { connect } from "react-redux"
import { compose } from "redux"
import _ from "lodash"

import RegisterStyles from "../../../stylesheets/register"
import TopNav from "../../../components/TopNav"
import TermNCondition from "../TermNCondition"
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import WithUser from "../../User/action"
import { storeUser } from "../../../actions/storeUser"

const options = [
  { value: "choice1", label: "Choice 1" },
  { value: "choice2", label: "Choice 2" },
  { value: "choice3", label: "Choice 3" },
]

const RegisterForm = (props) => {
  const [showLoading, setShowLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [form_data, set_form_data] = useState({
    name: "",
    ic_num: "",
    address: "",
    poscode: "",
    shop_name: "",
    business_type: "",
    phone_num: "",
    email: "",
    password1: "",
    password2: "",
    event: [],
    bin: [],
  })
  const [error, setError] = useState({})
  const toast = useToast()
  const database = firebase.firestore()

  const dataValidation = () => {
    let checkStatus = true
    setError({})
    Object.keys(form_data).map((val) => {
      if (form_data[val] === "" || form_data[val] === undefined) {
        checkStatus = false
        if (val === "business_type")
          setError((error) => ({
            ...error,
            [val]: "Please select a business type from the list",
          }))
        else
          setError((error) => ({ ...error, [val]: "This field is required" }))
      } else {
        if (typeof form_data[val] == "string") {
          set_form_data((prev) => ({ ...prev, [val]: form_data[val].trim() }))
        }
        switch (val) {
          case "name":
            if (!validateName(form_data[val])) {
              checkStatus = false
              setError((error) => ({
                ...error,
                [val]:
                  "Please provide valid name which only contains alphabetical values",
              }))
            }
            break
          case "ic_num":
            let ic_temp = form_data[val].replace(/-/g, "")
            if (ic_temp.length < 12 || ic_temp.length > 12) {
              checkStatus = false
              setError((error) => ({
                ...error,
                [val]:
                  "Please provide valid IC Number. \n(Eg. XXXXXX-XX-XXXX or XXXXXXXXXXXX)",
              }))
            }
            break
          case "phone_num":
            let phone_temp = form_data[val].replace(/-/g, "")
            if (phone_temp.length < 10 || phone_temp.length > 10) {
              checkStatus = false
              setError((error) => ({
                ...error,
                [val]:
                  "Please provide valid Phone Number. \n(Eg. XXX-XXXXXXX or XXXXXXXXXX)",
              }))
            }
            break
          case "poscode":
            if (
              isNaN(form_data[val]) ||
              form_data[val].length < 5 ||
              form_data[val].length > 5
            ) {
              checkStatus = false
              setError((error) => ({
                ...error,
                [val]: "Please provide valid Poscode",
              }))
            }
            break
          case "password1":
            if (form_data[val].length < 8) {
              checkStatus = false
              setError((error) => ({
                ...error,
                [val]: "Password length must not be lesser than 8",
              }))
            }
            if (form_data["password1"] != form_data["password2"]) {
              checkStatus = false
              setError((error) => ({
                ...error,
                [val]: "Password is not same as the confirmation password",
              }))
            }
            break
          case "shop_name":
            if (!validateName(form_data[val])) {
              checkStatus = false
              setError((error) => ({
                ...error,
                [val]:
                  "Please provide valid name which only contains alphabetical values",
              }))
            }
            break
          default:
        }
      }
    })

    setShowModal(checkStatus)
  }

  const validateName = (name) => {
    let isValidName = true
    if (
      /[!@#$%^&*(),.?":{}|<>]/g.test(name) ||
      /\d+/g.test(name)
    ) {
      isValidName = false
    }
    return isValidName
  }

  const signUp = () => {
    setShowLoading(true)
    form_data.amount = 0
    delete form_data.password2
    form_data.event = []
    form_data.bin = []
    var userRegister = database.collection("users").doc(form_data.ic_num)
    var userSignUp = userRegister
      .set(form_data)
      .then(() => {
        toast.show({
          title: "Daftar Berjaya.",
          placement: "bottom",
          status: "success",
        })
        setShowLoading(false)
        setShowModal(false)
        props.navigation.navigate("login")
      })
      .catch((error) => {
        toast.show({
          title: "Error: " + error,
          placement: "bottom",
          status: "warning",
        })
      })
  }

  useEffect(() => {
    set_form_data({
        name: props.user.name,
        ic_num: props.user.ic_num,
        shop_name: "",
        address: props.user.address,
        poscode: "",
        business_type: "",
        phone_num: props.user.phone_num,
        email: props.user.email,
        password1: "",
        password2: "",
        event: [],
      })
  }, [])

  return (
    <Box style={RegisterStyles.formContainer} flex={1}>
      <TopNav
        textColor="black"
        rightComponent="close"
        navigation={props.navigation}
      />
      <ScrollView m={2}>
        <VStack space={2}>
          <FormControl isInvalid={"name" in error}>
            <Input
              placeholder="Name"
              value={form_data.name}
              onChangeText={(value) =>
                set_form_data({ ...form_data, name: value })
              }
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="account-circle" />}
                  m={2}
                  mr={0}
                />
              }
            />
            {"name" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.name}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"ic_num" in error}>
            <Input
              placeholder="No. MyKad"
              value={form_data.ic_num}
              onChangeText={(value) =>
                set_form_data({ ...form_data, ic_num: value })
              }
              InputLeftElement={
                <Icon as={<MaterialIcons name="badge" />} m={2} mr={0} />
              }
            />
            {"ic_num" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.ic_num}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"shop_name" in error}>
            <Input
              placeholder="Nama Kedai"
              value={form_data.shop_name}
              onChangeText={(value) =>
                set_form_data({ ...form_data, shop_name: value })
              }
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="account-circle" />}
                  m={2}
                  mr={0}
                />
              }
            />
            {"shop_name" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.shop_name}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"address" in error}>
            <Input
              placeholder="Alamat"
              value={form_data.address}
              onChangeText={(value) =>
                set_form_data({ ...form_data, address: value })
              }
              InputLeftElement={
                <Icon as={<MaterialIcons name="room" />} m={2} mr={0} />
              }
            />
            {"address" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.address}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"poscode" in error}>
            <Input
              placeholder="Poskod"
              value={form_data.poscode}
              onChangeText={(value) =>
                set_form_data({ ...form_data, poscode: value })
              }
              InputLeftElement={
                <Icon as={<MaterialIcons name="room" />} m={2} mr={0} />
              }
            />
            {"poscode" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.poscode}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"business_type" in error}>
            <Select
              accessibilityLabel="Jenis Perniagaan"
              placeholder="Jenis Perniagaan"
              selectedValue={form_data.business_type}
              onValueChange={(value) =>
                set_form_data({ ...form_data, business_type: value })
              }
              InputLeftElement={
                <Icon as={<MaterialIcons name="store" />} m={2} mr={0} />
              }
            >
              <Select.Item label="" value="" />
              <Select.Item
                label="Kedai makanan/Restoran makanan"
                value="makanan"
              />
              <Select.Item label="Kedai Runcit/Serbaneka" value="runcit" />
              <Select.Item label="Stesen Minyak" value="minyak" />
              <Select.Item label="Lain-lain" value="lain" />
            </Select>
            {"business_type" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.business_type}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"phone_num" in error}>
            <Input
              placeholder="No. Telefon"
              value={form_data.phone_num}
              onChangeText={(value) =>
                set_form_data({ ...form_data, phone_num: value })
              }
              InputLeftElement={
                <Icon as={<MaterialIcons name="phone" />} m={2} mr={0} />
              }
            />
            {"phone_num" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.phone_num}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"email" in error}>
            <Input
              placeholder="E-mel"
              value={form_data.email}
              onChangeText={(value) =>
                set_form_data({ ...form_data, email: value })
              }
              InputLeftElement={
                <Icon as={<MaterialIcons name="email" />} m={2} mr={0} />
              }
            />
            {"email" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.email}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"password1" in error}>
            <Input
              placeholder="Kata Laluan"
              type={showPassword1 ? "text" : "password"}
              value={form_data.password1}
              onChangeText={(value) =>
                set_form_data({ ...form_data, password1: value })
              }
              InputRightElement={
                <IconButton
                  icon={
                    <Icon
                      as={
                        <MaterialIcons
                          name={showPassword1 ? "visibility-off" : "visibility"}
                        />
                      }
                    />
                  }
                  onPress={() => setShowPassword1(!showPassword1)}
                />
              }
              InputLeftElement={
                <Icon as={<MaterialIcons name="lock" />} m={2} mr={0} />
              }
            />
            {"password1" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.password1}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          <FormControl isInvalid={"password2" in error}>
            <Input
              placeholder="Ulang Kata Laluan"
              type={showPassword2 ? "text" : "password"}
              value={form_data.password2}
              onChangeText={(value) =>
                set_form_data({ ...form_data, password2: value })
              }
              InputRightElement={
                <IconButton
                  icon={
                    <Icon
                      as={
                        <MaterialIcons
                          name={showPassword2 ? "visibility-off" : "visibility"}
                        />
                      }
                    />
                  }
                  onPress={() => setShowPassword2(!showPassword2)}
                />
              }
              InputLeftElement={
                <Icon as={<MaterialIcons name="lock" />} m={2} mr={0} />
              }
            />
            {"password2" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.password2}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
        </VStack>
      </ScrollView>
      <Button
        borderTopRadius={0}
        borderBottomRadius={20}
        py={4}
        colorScheme="teal"
        _text={{ color: "white" }}
        onPress={() => dataValidation()}
      >
        Daftar
      </Button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Pengesahan</Modal.Header>
          <Text>Sila pastikan semua maklumat yang diisi adalah betul.</Text>
          {/* <Modal.Header>Terma dan Syarat</Modal.Header>
          <TermNCondition /> */}
          <Modal.Footer>
            <Button.Group variant="ghost" space={2}>
              <Button
                onPress={() => {
                  setShowModal(false)
                }}
              >
                Batal
              </Button>
              <Button
                onPress={() => {
                  signUp()
                  setShowModal(false)
                }}
              >
                Teruskan
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
})

const mapDispatchToProps = (dispatch) => ({
  actions: (val) => {
    dispatch(storeUser(val))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
