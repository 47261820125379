import React, { useState, useEffect, useRef } from "react";
import Moment from 'moment'
import {
  FlatList,
  HStack,
  VStack,
  Text,
  Icon,
  Box,
  Center,
  SmallCloseIcon,
} from "native-base";
import { Animated, View, RefreshControl, Dimensions } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { connect } from "react-redux";
import firebase from "firebase/app"
import "firebase/firestore"

import TopNav from "../../../components/TopNav";
import TabMenu from "../../../components/TabMenu";

const checkValue = (data) => {
  let value = parseFloat(data);
  if (value < 0) return false;
  else return true;
};

const TransactionHistory = (props) => {
  const [transactions, setTransactions] = useState([]);
  const visibility = useRef(new Animated.Value(0)).current;
  const [refreshing, setRefreshing] = useState(false)
  const database = firebase.firestore()

  function compare(a, b) {
    if (a < b) {
      return 1
    }
    if ( a > b) {
      return -1
    }    
    return 0
  }

  useEffect(() => {
    setTransactions([]);
    props.transactions.length>0 && setTransactions(props.transactions);
  }, []);
  
  const refreshInfo = () => {
    setRefreshing(true)
    let temp = {}
    if (props.user.name != "") {
      let event = props.user.event.sort(compare)
      for (let i = 0; i < event.length; i++) {
        database
          .collection("event")
          .doc(event[i])
          .get()
          .then((res2) => {
            temp = res2.data()
            if(i===0)
              setTransactions((currentState) => [temp])
            else
              setTransactions((currentState) => [...currentState, temp])
          })
      }
      transactions && setTransactions( transactions)
      setRefreshing(false)
    }
  }

  return (
    <>
      <TopNav rightComponent="notification" navigation={props.navigation} />
      <Box
        width={"100%"}
        height={Dimensions.get("window").height*0.9}
        borderTopRadius={50}
        backgroundColor="white"
        py={5}
        top={0}
        bottom={10}
      >
        <HStack mx={5} alignItems="center">
          <Text bold>Semua Transaksi</Text>
        </HStack>
        {transactions.length === 0 ? (
          <View>
            <Center p={10}>
              <SmallCloseIcon />
              <Text bold>Tiada Kutipan</Text>
            </Center>
          </View>
        ) : (
          <FlatList
            mx={5}
            mb={10}
            data={transactions}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={refreshInfo} />
            }
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item }) => {
              return (
                <HStack alignItems="center" py={2}>
                  <Icon
                    as={
                      <MaterialCommunityIcons
                        name={
                          checkValue(item.transaction_amount)
                            ? "arrow-up-drop-circle-outline"
                            : "arrow-down-drop-circle-outline"
                        }
                      />
                    }
                    color={
                      checkValue(item.transaction_amount) ? "#32CD32" : "red"
                    }
                    flex={1}
                  />
                  <VStack flex={5}>
                    <Text bold fontSize="sm">
                      {item.name}
                    </Text>
                    {
                      item.premis && (
                        <Text bold fontSize="sm">
                          (Premis: {item.premis})
                        </Text>
                      )
                    }
                    <HStack alignItems="center" space={1}>
                      <Text fontSize="xs">{item.date}</Text>
                      <Text bold fontSize="lg">
                        ·
                      </Text>
                      <Text fontSize="xs">{item.time}</Text>
                    </HStack>
                  </VStack>
                  {item.type === "null" ? (
                    <Text flex={1}></Text>
                  ) : (
                    <Text flex={1} color="#445AD8" fontSize="sm" bold>
                      {item.type}L
                    </Text>
                  )}
                  <Text flex={3} textAlign="right">
                    {checkValue(item.transaction_amount)
                      ? "RM" + parseFloat(item.transaction_amount).toFixed(2)
                      : "-RM" +
                        parseFloat(item.transaction_amount)
                          .toFixed(2)
                          .toString()
                          .replace("-", "")}
                  </Text>
                </HStack>
              );
            }}
          ></FlatList>
        )}
      </Box>
      {/* <TabMenu navigation={props.navigation} activePage={props.navigation.isFocused?"history":""}/> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
  transactions: state.userReducer.events,
});

export default connect(mapStateToProps)(TransactionHistory);
