import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  Button,
  Icon,
  FlatList,
  Link,
  SmallCloseIcon,
  ScrollView,
} from "native-base"
import React, { useEffect, useState, useRef } from "react"
import Moment from "moment"
import {
  MaterialIcons,
  MaterialCommunityIcons,
  Entypo,
} from "@expo/vector-icons"
import {
  Image,
  View,
  Animated,
  RefreshControl,
  Alert,
  Dimensions,
  Platform,
} from "react-native"
import { connect } from "react-redux"
import { storeUser, storeEvent } from "../../../actions/storeUser"
import firebase from "firebase/app"
import "firebase/firestore"

import UserMainStyles from "../../../stylesheets/userMain"
import TopNav from "../../../components/TopNav"
import * as Notifications from "expo-notifications"
import TabMenu from "../../../components/TabMenu"

const paymentLogo = require("../../../assets/MPSPpay.png")

const HomeScreen = (props) => {
  const checkValue = (data) => {
    let value = parseFloat(data)
    if (value < 0) return false
    else return true
  }

  const [userData, setUserData] = useState({
    name: "",
    ic_num: "",
    address: "",
    poscode: "",
    business_type: "",
    phone_num: "",
    email: "",
    password1: "",
    amount: 0,
  })
  const [userEvent, setUserEvent] = useState([])
  const [refreshing, setRefreshing] = useState(false)
  const [notification, setNotification] = useState(false)
  const notificationListener = useRef()
  const responseListener = useRef()
  const database = firebase.firestore()
  const visibility = useRef(new Animated.Value(0)).current
  let businessType = {
    makanan: "Kedai makanan/Restoran makanan",
    runcit: "Kedai Runcit/Serbaneka",
    minyak: "Stesen Minyak",
    lain: "Lain-lain",
  }

  function compare(a, b) {
    if (a < b) {
      return 1
    }
    if (a > b) {
      return -1
    }
    return 0
  }

  useEffect(() => {
    setUserData(props.user)
    fetchUser()
  }, [])

  useEffect(() => {
    props.storeEvent(userEvent)
  }, [userEvent])

  const refreshInfo = () => {
    setRefreshing(true)
    setNotification(null)
    fetchUser()
  }

  const fetchUser = () => {
    var directory = database.collection("users").doc(props.user.ic_num)
    directory
      .get()
      .then((res) => {
        props.storeUser(res.data())
        let temp = {}
        if (props.user.name != "") {
          setUserEvent([])
          let event = res.data().event.sort(compare)
          for (let i = 0; i < event.length; i++) {
            database
              .collection("event")
              .doc(event[i])
              .get()
              .then((res2) => {
                temp = res2.data()
                if (i === 0) setUserEvent((currentState) => [temp])
                else setUserEvent((currentState) => [...currentState, temp])
              })
              .catch((error) => {
                toast.show({
                  title: "Error: " + error,
                  placement: "bottom",
                  status: "warning",
                })
              })
          }
        }
      })
      .catch((error) => {
        toast.show({
          title: "Error: " + error,
          placement: "bottom",
          status: "warning",
        })
      })

    setRefreshing(false)
    setUserEvent(userEvent)
  }

  return (
    <>
      <TopNav rightComponent="faq" navigation={props.navigation} />
      <Box
        flex={1}
        w={Platform.OS === "web" ? "100vw" : "100%"}
        h={Platform.OS === "web" ? "82vh" : "100%"}
      >
        <ScrollView
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={refreshInfo} />
          }
        >
          <VStack
            alignItems="center"
            space={5}
            height={Platform.OS === "web" ? "82vh" : Dimensions.get("window").height * 0.82}
          >
            <Box width={"90%"} borderRadius={20} backgroundColor="white">
              <VStack alignItems="center" space={5} mx={5} my={4}>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Nama
                  </Text>
                  <Text flex={2} fontSize="sm" bold>
                    {props.user.name}
                  </Text>
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Nama Kedai
                  </Text>
                  <Text
                    flex={2}
                    fontSize="sm"
                    bold={props.user.shop_name}
                    style={{ color: props.user.shop_name ? "black" : "grey" }}
                  >
                    {props.user.shop_name
                      ? props.user.shop_name
                      : "Tidak ditetapkan"}
                  </Text>
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Jenis Perniagaan
                  </Text>
                  <Text flex={2} fontSize="sm" bold>
                    {businessType[props.user.business_type]}
                  </Text>
                </HStack>
              </VStack>
            </Box>
            <Center>
              <Text color="white" fontSize="4xl" bold>
                RM {props.user.amount.toFixed(2)}
              </Text>
              <Text color="white" fontSize="lg">
                Jumlah Baki
              </Text>
            </Center>
            <HStack space={4} px={4}>
              <Button
                startIcon={
                  <Icon as={<Entypo name="wallet" />} color="#445AD8" />
                }
                flex={1}
                backgroundColor="white"
                onPress={() => props.navigation.navigate("topUp")}
              >
                <Text color="black" bold fontSize="sm" mb={1}>
                  Tambah Nilai
                </Text>
                <Image source={paymentLogo} style={UserMainStyles.logo} />
              </Button>
              <Button
                startIcon={
                  <Icon as={<MaterialIcons name="qr-code" />} color="#445AD8" />
                }
                flex={1}
                backgroundColor="white"
                onPress={() => props.navigation.navigate("showQR")}
              >
                <Text color="black" bold fontSize="sm">
                  Hasilkan kod QR
                </Text>
              </Button>
            </HStack>
            <Box
              flex={1}
              width={"100%"}
              h="100%"
              borderTopRadius={50}
              borderBottomRadius={0}
              backgroundColor="white"
              pt={5}
            >
              <HStack mx={5} alignItems="center">
                <Text bold>Transaksi Semasa</Text>
                <Link
                  variant="ghost"
                  marginLeft="auto"
                  onPress={() => props.navigation.navigate("history")}
                >
                  <Text color="#445AD8" bold>
                    Lihat Semua
                  </Text>
                </Link>
              </HStack>
              {userEvent.length === 0 ? (
                <View>
                  <Center p={10}>
                    <SmallCloseIcon />
                    <Text bold>Tiada Kutipan</Text>
                  </Center>
                </View>
              ) : (
                <FlatList
                  mx={5}
                  flex={1}
                  data={userEvent.slice(0, 2)}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={({ item }) => {
                    return (
                      <HStack alignItems="center" py={2}>
                        <Icon
                          as={
                            <MaterialCommunityIcons
                              name={
                                checkValue(item.transaction_amount)
                                  ? "arrow-up-drop-circle-outline"
                                  : "arrow-down-drop-circle-outline"
                              }
                            />
                          }
                          color={
                            checkValue(item.transaction_amount)
                              ? "#32CD32"
                              : "red"
                          }
                          flex={1}
                        />
                        <VStack flex={5}>
                          <Text bold fontSize="sm">
                            {item.name}
                          </Text>
                          {
                            item.premis && (
                              <Text bold fontSize="sm">
                                (Premis: {item.premis})
                              </Text>
                            )
                          }
                          <HStack alignItems="center" space={1}>
                            <Text fontSize="xs">{item.date}</Text>
                            <Text bold fontSize="lg">
                              ·
                            </Text>
                            <Text fontSize="xs">{item.time}</Text>
                          </HStack>
                        </VStack>
                        {item.type === "null" ? (
                          <Text flex={1}></Text>
                        ) : (
                          <Text flex={1} color="#445AD8" fontSize="sm" bold>
                            {item.type}L
                          </Text>
                        )}
                        <Text flex={3} textAlign="right">
                          {checkValue(item.transaction_amount)
                            ? "RM" +
                              parseFloat(item.transaction_amount).toFixed(2)
                            : "-RM" +
                              parseFloat(item.transaction_amount)
                                .toFixed(2)
                                .toString()
                                .replace("-", "")}
                        </Text>
                      </HStack>
                    )
                  }}
                ></FlatList>
              )}
              {/* <Fab
          position="absolute"
          size="sm"
          icon={<Icon color="white" as={<MaterialIcons name="add" />} size="sm" />}
          onPress={ () => {
            calltestCors()
          }}
          /> */}
            </Box>
          </VStack>
        </ScrollView>
      </Box>
      {/* <TabMenu navigation={props.navigation} activePage={props.navigation.isFocused?"homeScreen":""}/> */}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
  transactions: state.userReducer.events,
})

const mapDispatchToProps = (dispatch) => ({
  storeUser: (val) => {
    dispatch(storeUser(val))
  },
  storeEvent: (val) => {
    dispatch(storeEvent(val))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
