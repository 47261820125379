import { Button, HStack, Text, VStack, Icon, View } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import React, { useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { Platform } from "react-native";

const size = "sm";
const font_size = "10px";
const background = "white";
const color = "#9E9E9E";
const active_color = "#4166f5";

const TabMenu = (props) => {
  const navigation = useNavigation();
  const state = navigation.dangerouslyGetState().routes[0].state;
  const route = state===undefined?state:state.routes;
  const activeRoute = route===undefined?"home":route[route.length - 1].name;
  // const activeRoute = "home";
  return (
    <View flex={1} width={"100%"} position="absolute" height={Platform.OS==="web"?"8vh":"8%"} backgroundColor="#fff" bottom={0}>
      <HStack
        alignItems="center"
        borderTopWidth={1}
        borderColor={active_color}
        alignItems="center"
      >
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          onPress={() => navigation.navigate("home")}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={activeRoute === "home" ? active_color : color}
              as={<MaterialIcons name={"home"} />}
            />
            <Text
              fontSize={font_size}
              color={activeRoute === "home" ? active_color : color}
            >
              Utama
            </Text>
          </VStack>
        </Button>
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          onPress={() => navigation.navigate("history")}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={activeRoute === "history" ? active_color : color}
              as={<MaterialIcons name={"payments"} />}
            />
            <Text
              fontSize={font_size}
              color={activeRoute === "history" ? active_color : color}
            >
              Transaksi
            </Text>
          </VStack>
        </Button>
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          onPress={() => navigation.navigate("topUp")}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={activeRoute === "topUp" ? active_color : color}
              as={<MaterialIcons name={"attach-money"} />}
            />
            <Text
              fontSize={font_size}
              color={activeRoute === "topUp" ? active_color : color}
            >
              Tambah Nilai
            </Text>
          </VStack>
        </Button>
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          onPress={() => navigation.navigate("showQR")}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={activeRoute === "showQR" ? active_color : color}
              as={<MaterialIcons name={"qr-code"} />}
            />
            <Text
              fontSize={font_size}
              color={activeRoute === "showQR" ? active_color : color}
            >
              Kod QR
            </Text>
          </VStack>
        </Button>
        <Button
          borderRadius={0}
          backgroundColor={background}
          flex={1}
          px={0}
          onPress={() => navigation.navigate("profile")}
        >
          <VStack alignItems="center">
            <Icon
              size={size}
              color={activeRoute === "profile" ? active_color : color}
              as={<MaterialIcons name={"account-circle"} />}
            />
            <Text
              fontSize={font_size}
              color={activeRoute === "profile" ? active_color : color}
            >
              Profil
            </Text>
          </VStack>
        </Button>
      </HStack>
    </View>
  );
};

export default TabMenu;
