import {
  Center,
  Box,
  Text,
  Button,
  Image,
  VStack,
  useToast,
  Select,
  IconButton,
  Icon,
  Modal,
  FormControl,
  Input,
} from "native-base"
import { Animated, Platform } from "react-native"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import QRCode from "react-native-qrcode-svg"
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons"
import * as FileSystem from "expo-file-system"
import { Picker } from "@react-native-picker/picker"
import * as MediaLibrary from "expo-media-library"

import { storeUser } from "../../../actions/storeUser"
import LoadingOverlay from "../../../components/LoadingOverlay"
import TopNav from "../../../components/TopNav"
import QRpwa from "./QRpwa"

const AddQR = (props) => {
  const toast = useToast()
  const [form_data, set_form_data] = useState({
    shop_name: "",
    license_num: "",
    address:"",
    bin_size: "240",
  })
  const [error, setError] = useState([])

  const dataValidation = () => {
    let checkStatus = true
    setError({})
    if (form_data["shop_name"] === "" || form_data["shop_name"] === undefined) {
      checkStatus = false
      setError((error) => ({ ...error, shop_name: "This field is required" }))
    }
    set_form_data((prev) => ({
      ...prev,
      shop_name: form_data["shop_name"].trim(),
    }))
    if (!validateName(form_data["shop_name"])) {
      checkStatus = false
      setError((error) => ({
        ...error,
        shop_name:
          "Please provide valid name which only contains alphabetical values",
      }))
    }

    if (form_data["license_num"] === "" || form_data["license_num"] === undefined) {
      checkStatus = false
      setError((error) => ({ ...error, license_num: "This field is required" }))
    }
    set_form_data((prev) => ({
      ...prev,
      license_num: form_data["license_num"].trim(),
    }))

    if (form_data["address"] === "" || form_data["address"] === undefined) {
      checkStatus = false
      setError((error) => ({ ...error, address: "This field is required" }))
    }
    set_form_data((prev) => ({
      ...prev,
      address: form_data["address"].trim(),
    }))

    if (checkStatus) {
      updateBinData()
    }
  }

  const updateBinData = () => {
    props.setLoading(true)
    props.setShowModal(false)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        ic_num: props.user.ic_num,
        location: form_data.shop_name,
        license_num: form_data.license_num,
        address: form_data.address,
        bin_size: form_data.bin_size,
      }),
    }
    fetch(
      "https://asia-southeast1-i-collect-sws.cloudfunctions.net/updateBin",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        props.setLoading(false)
        if (data == "success") {
          props.setBinData([
            ...props.binData,
            {
              id: props.binData.length + 1,
              location: form_data.shop_name,
              license_num: form_data.license_num,
              address: form_data.address,
              bin_size: form_data.bin_size,
            },
          ])
          let tmp = props.user
          tmp.bin = [
            ...props.binData,
            {
              id: props.binData.length + 1,
              location: form_data.shop_name,
              license_num: form_data.license_num,
              address: form_data.address,
              bin_size: form_data.bin_size,
            },
          ]
          props.actions(tmp)
          set_form_data({
            shop_name: "",
            license_num: "",
            address: "",
            bin_size: "240",
          })
          toast.show({
            title: "Kod QR dijana",
            placement: "bottom",
            status: "success",
          })
        } else {
          toast.show({
            title: "Sila cuba sekali lagi",
            placement: "bottom",
            status: "danger",
          })
        }
      })
  }

  const validateName = (name) => {
    let isValidName = true
    if (/[!@#$%^&*(),.?":{}|<>]/g.test(name)) {
      isValidName = false
    }
    return isValidName
  }

  return (
    <Modal isOpen={props.showModal} onClose={() => props.setShowModal(false)}>
      <Modal.Content maxWidth="400px">
        <Modal.CloseButton />
        <Modal.Header>QR Baru</Modal.Header>
        <Modal.Body>
          Nama Kedai:
          <FormControl isInvalid={"shop_name" in error} py={2} pb={5}>
            <Input
              placeholder="Nama Kedai"
              value={form_data.shop_name}
              onChangeText={(value) =>
                set_form_data({ ...form_data, shop_name: value })
              }
            />
            {"shop_name" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.shop_name}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          Lesen Perniagaan
          <FormControl isInvalid={"license_num" in error} py={2} pb={5}>
            <Input
              placeholder="Lesen Perniagaan"
              value={form_data.license_num}
              onChangeText={(value) =>
                set_form_data({ ...form_data, license_num: value })
              }
            />
            {"license_num" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.license_num}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          Alamat:
          <FormControl isInvalid={"address" in error} py={2} pb={5}>
            <Input
              placeholder="Lesen Perniagaan"
              value={form_data.address}
              onChangeText={(value) =>
                set_form_data({ ...form_data, address: value })
              }
            />
            {"address" in error ? (
              <FormControl.ErrorMessage
                mb={1}
                mt={0}
                _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
              >
                {error.address}
              </FormControl.ErrorMessage>
            ) : (
              <></>
            )}
          </FormControl>
          Saiz Tong Sampah:
          <FormControl pt={2}>
            <Select
              accessibilityLabel="Saiz Tong Sampah"
              placeholder="Saiz Tong Sampah"
              selectedValue={form_data.bin_size}
              onValueChange={(value) =>
                set_form_data({ ...form_data, bin_size: value })
              }
            >
              <Select.Item label="240L" value="240" />
              <Select.Item label="660L" value="660" />
            </Select>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group variant="ghost" space={2}>
            <Button
              onPress={() => {
                props.setShowModal(false)
              }}
              colorScheme={"danger"}
            >
              Batalkan
            </Button>
            <Button
              onPress={() => {
                dataValidation()
              }}
            >
              Hantarkan
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

const ShowQR = (props) => {
  const [imgBase64, setImgBase64] = useState({})
  const [selectedBin, setBin] = useState(0)
  const [binData, setBinData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  useEffect(() => {
    setBinData(props.user.bin)
  }, [])

  const downloadQR = () => {
    if (Platform.OS === "web") {
      const canvas = document.querySelector(".QRContainer > canvas")
      let ImageBase64 = canvas.toDataURL()
      var a = document.createElement("a")
      a.href = ImageBase64
      a.download =
        binData[selectedBin].location +
        "_" +
        binData[selectedBin].bin_size +
        "L" +
        ".png"
      a.click()
    } else {
      const filename =
        FileSystem.documentDirectory + props.user.location + ".png"
      imgBase64.toDataURL((data) =>
        FileSystem.writeAsStringAsync(filename, data, {
          encoding: FileSystem.EncodingType.Base64,
        })
          .then(() => MediaLibrary.saveToLibraryAsync(filename))
          .then(
            toast.show({
              title: "Muat turun berjaya.",
              placement: "bottom",
            })
          )
      )
    }
  }

  return (
    <>
      <TopNav rightComponent="notification" navigation={props.navigation} />
      {isLoading && <LoadingOverlay />}
      <Center width={"100%"} height={"90%"} bottom={10}>
        <VStack space={5} width={"100%"} alignItems="center">
          <Box backgroundColor="white" borderRadius={20} width={"80%"} p={2}>
            <IconButton
              icon={
                <Icon
                  size="md"
                  as={<MaterialIcons name="add" />}
                  color="black"
                />
              }
              onPress={() => {
                setShowModal(true)
              }}
              alignSelf="flex-end"
              width={10}
              height={10}
            />
            <Center>
              {binData[selectedBin] ? (
                Platform.OS === "web" ? (
                  <div className="QRContainer">
                    <QRpwa
                      value={
                        props.user.uuid +
                        "@" +
                        binData[selectedBin].id +
                        "?" +
                        binData[selectedBin].bin_size
                      }
                    />
                  </div>
                ) : (
                  <QRCode
                    value={
                      props.user.uuid +
                      "@" +
                      binData[selectedBin].id +
                      "?" +
                      binData[selectedBin].bin_size
                    }
                    size={225}
                    getRef={(base64) => setImgBase64(base64)}
                    quietZone={15}
                  />
                )
              ) : (
                <Box
                  height={225}
                  mx={2}
                  textAlign="center"
                  justifyContent="center"
                >
                  <Text bold>Tiada Kod QR. Sila tambah tong sampah baru.</Text>
                </Box>
              )}
              <Text fontWeight={ 500 }>Alamat</Text>
              {
                ( binData[selectedBin] && binData[selectedBin].address )
                  ? <Text textAlign="justify" fontSize="sm">{ binData[selectedBin].address}</Text>
                  : <Text textAlign="justify" fontSize="sm">Tiada Alamat</Text>
              }
              {binData && Platform.OS === "android" ? (
                <Select
                  selectedValue={selectedBin}
                  onValueChange={(itemValue) => setBin(itemValue)}
                  _selectedItem={{
                    bg: "cyan.600",
                  }}
                  width={"100%"}
                  mt={2}
                  textAlign="center"
                >
                  {binData.map((item, index) => {
                    return (
                      <Select.Item
                        label={item.location + " ( " + item.bin_size + "L ) "}
                        value={index}
                      />
                    )
                  })}
                </Select>
              ) : (
                <Picker
                  selectedValue={selectedBin}
                  onValueChange={(itemValue, itemIndex) => setBin(itemValue)}
                  _selectedItem={{
                    bg: "cyan.600",
                  }}
                  mt={2}
                  style={{
                    width: "90%",
                    textAlign: "center",
                    fontSize: "1.125rem",
                    padding: 10,
                    borderColor: "#E5E5E5",
                    borderRadius: 10,
                    marginTop: 5,
                  }}
                >
                  {binData.map((item, index) => {
                    return (
                      <Picker.Item
                        label={item.location + " ( " + item.bin_size + "L ) "}
                        value={index}
                      />
                    )
                  })}
                </Picker>
              )}
            </Center>
          </Box>
          <Text textAlign="center" color="white" fontSize="sm">
            {"Kod QR harus dicetak \ndan dilekatkan pada tong sampah"}
          </Text>
          <Button onPress={() => downloadQR()}>Muat Turun</Button>
        </VStack>
      </Center>
      <AddQR
        showModal={showModal}
        setShowModal={setShowModal}
        binData={binData}
        setBinData={setBinData}
        actions={()=>props.actions}
        user={props.user}
        setLoading={setLoading}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
})

const mapDispatchToProps = (dispatch) => ({
  actions: (val) => {
    dispatch(storeUser(val))
  }
})

export default connect(mapStateToProps,mapDispatchToProps)(ShowQR)
