let initialState = {
  credentials: {
    ic_num: "",
    password: "",
    name: ""
  }
};

const CredReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_LOGIN":
      return{
        ...state,
        credentials: action.payload,
      }
    default:
      return state;
  }
};

export default CredReducer;
