import {
  Text,
  Input,
  Button,
  VStack,
  Link,
  useToast,
  Center,
  Box,
} from "native-base";
import React, { useState, useEffect } from "react";

import TopNav from "../../../components/TopNav";

const EnterCode = (props) => {
  const [codeReceived, setCodeReceived] = useState("");
  const [timer, setTimer] = useState(60);
  const [startTimer, setStartTimer] = useState(false);
  const toast = useToast();

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        return lastTimerCount - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [startTimer]);

  return (
    <>
      <TopNav rightComponent="close" navigation={props.navigation}/>
      <Center flex={1}>
        <Box width={"90%"} borderRadius={20} backgroundColor="white" p={5}>
          <VStack space={2}>
            <Text bold fontSize="lg">
              Masukkan Kod
            </Text>
            <Text>
              Sila masukkan kod yang telah dihantar kepada E-mel anda.
            </Text>
            <Input
              variant="underlined"
              value={codeReceived}
              placeholder="Kod yang diterima"
              onChangeText={(val) => {
                setCodeReceived(val);
              }}
            />
            <>
              <Text fontSize="sm">Tidak Terima E-mel?</Text>
              {timer != 0 ? (
                <Text fontSize="sm">
                  Sila tunggu sebentar :
                  <Text fontSize="sm" bold>
                    {timer}
                  </Text>
                </Text>
              ) : (
                <Link
                  onPress={() => {
                    setTimer(60);
                    setStartTimer(!startTimer);
                    toast.show({
                      title: "Emel telah dihantar semula.",
                      placement: "bottom",
                      status: "success",
                    });
                  }}
                >
                  <Text fontSize="sm" color="#445AD8" underline>
                    Hantar Semula
                  </Text>
                </Link>
              )}
            </>
            <Button colorScheme="teal" onPress={()=>{
              props.navigation.navigate("password", { 
                code: codeReceived,
                ic_num: props.route.params.ic_num
              });
              }}>Hantar</Button>
          </VStack>
        </Box>
      </Center>
    </>
  );
};

export default EnterCode;
