import { combineReducers } from "redux";
import userReducer from "./storeUser";
import CredReducer from "./storeCredentials";

const reducers = combineReducers({
  userReducer,
  CredReducer
});

export default reducers;
