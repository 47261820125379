import { Box, Center, VStack, Input, Text, Button, useToast } from "native-base";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { storeUser, clearData } from "../../../actions/storeUser";
import LoadingOverlay from "../../../components/LoadingOverlay";
import TopNav from "../../../components/TopNav";
const CryptoJS = require("crypto-js")

const RegisterIC = (props) => {
  const [ic_num, setIC] = useState("");
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => props.resetRedux(), []);

  const checkExistUser = () => {
    const hash = CryptoJS.SHA256(ic_num+'7VsSFkPPnnWdbX6b')
    fetch(`https://speedbiz.mbsp.gov.my/api/icollect/getuserinformation?ic_num=${ic_num}&hash=${hash}`, { method: "GET"})
    .then((response) => response.json() )
    .then((data) => {
      setLoading(false)
      console.log( data )
      if( data.Data.length > 0) {
        props.actions({ 
          ...props.user,
           ic_num: ic_num.trim(),
           name: data.Data[0].full_name,
           address: data.Data[0].address,
           license_num: data.Data[0].license_num,
           phone_num: data.Data[0].phone_num,
           email: data.Data[0].email,
          });
        toast.show({
          title: 'Maklumat Pengguna Dijumpai.',
          placement: "bottom",
          status: "warning",
        });
      }else{
        props.actions({ ...props.user, ic_num: ic_num.trim() })
        toast.show({
          title: 'Pengguna Baru.',
          placement: "bottom",
          status: "warning",
        });
      }
      props.navigation.navigate("form");
    }).catch(() => {
      toast.show({
        title: 'Unexpected Server Error',
        placement: "bottom",
        status: "danger",
      });
    })
  }

  const checkIC = () => {
    setLoading(true)
    const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify({
        ic_num: ic_num,
      }),
    };
    fetch(
      "https://asia-southeast1-i-collect-sws.cloudfunctions.net/icRegister",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if(data.message === 'User Exist'){
          setLoading(false)
          toast.show({
            title: data.message,
            placement: "bottom",
            status: "danger",
          });
        }else if (data.message === 'null'){
          checkExistUser()
        }
      });
  }

  return (
    <>
      <TopNav rightComponent="close" navigation={props.navigation} />
      <Center flex={1}>
      {isLoading && <LoadingOverlay />}
        <Box width={"90%"} borderRadius={20} backgroundColor="white" p={5}>
          <VStack space={2}>
            <Text bold fontSize="lg">
              Daftar akaun Baharu
            </Text>
            <Text>Sila masukkan No. MyKad anda.</Text>
            <Input
              variant="underlined"
              placeholder="No. MyKad"
              value={ic_num}
              onChangeText={(val) => {
                setIC(val);
              }}
            />
            <Button
              onPress={() => {
                
                checkIC()
              }}
              colorScheme="teal"
            >
              Hantar
            </Button>
          </VStack>
        </Box>
      </Center>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
  actions: (val) => {
    dispatch(storeUser(val));
  },
  resetRedux: () => {
    dispatch(clearData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterIC);
