import {
  Center,
  VStack,
  Heading,
  Button,
  Text,
  HStack,
  Box,
  Icon,
  IconButton
} from "native-base";
import { Image, Platform } from "react-native";
import React from "react";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useState } from 'react' 

import TopNavStyles from "../../stylesheets/topNav";
import OrderModal from "../../container/MainScreen/Modal/OrderModal"
import FAQ from "./FAQModal"

const logo = require("../../assets/MBSP_logo.png");

const createCollection = () => {
  const requestOptions = {
    method: "POST",
    headers:{
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With', 
      'Content-Type': ' application/json; charset=utf-8',
    },
    body: JSON.stringify({
      'batch_id': batch_id,
      'total_amount': total_amount,
      'secretkey': secretkey,
      'datajson': datajson,
      'hash': hash,
      'email': user.email,
    })
  }
  console.log(requestOptions)
  fetch(
    "https://mbsppay.mbsp.gov.my/ws/gateway.php",
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
  });
}

const TopNav = (props) => {
  const [ showModal, setShowModal ] = useState(false)
  const [ showFAQ, setShowFAQ ] = useState(false)
  const LogOutBtn = () => {
    return (
      <Button
        size="xs"
        borderRadius="20px"
        px={2}
        onPress={() => props.navigation.navigate('login')}
      >
        <Text fontSize="xs" color="white">
          Log Out
        </Text>
      </Button>
    );
  };

  const CloseBtn = () => {
    return (
      <IconButton
        icon={
          <Icon size="md" as={<MaterialIcons name="close"/>} color={props.textColor == undefined ? "white" : props.textColor }/>
        }
        onPress={() => props.navigation.navigate('login')}
      />
    );
  };

  const CancelTopUpBtn = () => {
    return (
      <IconButton
        icon={
          <Icon size="md" as={<MaterialIcons name="close"/>} color={props.textColor == undefined ? "white" : props.textColor }/>
        }
        onPress={() => props.navigation.popToTop()}
      />
    );
  };

  const NotificationBtn = () => {
    return (
      <IconButton
        icon={
          <Icon size="md" as={<MaterialCommunityIcons name="bell" />} color="white"/>
        }
        onPress={() => props.navigation.toggleDrawer()}
      />
    );
  }

  const FAQBtn = () => {
    return (
      <IconButton
        icon={
          <Icon size="md" as={<MaterialCommunityIcons name="help-circle-outline" />} color="white"/>
        }
        onPress={() => setShowFAQ(true)}
      />
    );
  }

  const CallForServicesBtn = () => {
    return (
      <IconButton
        icon={
          <Icon size="md" as={<MaterialCommunityIcons name="dump-truck" />} color="white"/>
        }
        onPress={()=>setShowModal(true)}
      />
    )
  }

  const showComponents = () => {
    if (props.rightComponent === "logout") return <LogOutBtn />
    if (props.rightComponent === "close") return <CloseBtn />
    if (props.rightComponent === "cancelTopUpBtn") return <CancelTopUpBtn />
    if (props.rightComponent === "faq") return <FAQBtn />
  };

  const showLeftComponents = () => {
    if (props.leftComponenet === 'add') return <EventButton />
  }

  return (
    <>
      <HStack alignItems="center" py={2} height={Platform.OS==="web"?"10vh":"10%"}>
        {props.leftComponent?<Center flex={2}>{<CallForServicesBtn/>}</Center>:<Box flex={2} />}
        <VStack alignItems="center" flex={6}>
          <Image source={logo} style={TopNavStyles.logo} />
          <Center>
            <Heading
              fontSize="12px"
              color={props.textColor == undefined ? "white" : props.textColor}
              textAlign="center"
            >
              Sistem i-Collect MBSP
            </Heading>
          </Center>
        </VStack>
        <Center flex={2}>{showComponents()}</Center>
      </HStack>
      <FAQ showModal={showFAQ} setShowModal={setShowFAQ}/>
      <OrderModal showModal={showModal} setShowModal={setShowModal}/>
    </>
  );
};

export default TopNav;
