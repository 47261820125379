let initialState = {
  users: {
    name: "",
    ic_num: "",
    address: "",
    poscode: "",
    business_type: "",
    license_num: "",
    phone_num: "",
    email: "",
    password1: "",
    event:[]
  },
  events:[]
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "STORE_USER":
      return {
        ...state,
        users: action.payload,
      };
      break;
    case "STORE_EVENT":
      return {
        ...state,
        events: action.payload,
      };
      break;
    case "CLEAR_DATA":
      return{
        initialState
      };
      break
    default:
      return state;
  }
};

export default userReducer;
