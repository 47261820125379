import { Modal, Button, ScrollView, VStack, Text } from "native-base"
import React from "react"

const FAQModal = (props) => {
  return (
    <Modal isOpen={props.showModal} onClose={() => props.setShowModal(false)}>
      <Modal.Content maxWidth="400px">
        <Modal.CloseButton />
        <Modal.Header>FAQ</Modal.Header>
        <Modal.Body>
          <ScrollView mr={4}>
            <VStack space={2}>
              <VStack>
                <Text fontSize="lg" bold>
                  Latest transaction is not showing 
                </Text>
                <Text textAlign="justify" fontSize="sm">
                  Go back to 'Utama' and pull down to refresh. 
                </Text>
              </VStack>
              <VStack>
                <Text fontSize="lg" bold>
                  Balance is incorrect after top up
                </Text>
                <Text textAlign="justify" fontSize="sm">
                  Go back to 'Utama' and pull down to refresh.
                </Text>
              </VStack>
              <VStack>
                <Text fontSize="lg" bold>
                  Unable to topup
                </Text>
                <Text textAlign="justify" fontSize="sm">
                  This may cause by enter incorrect OTP or accidentally exit the payment process.
                </Text>
                <Text textAlign="justify" fontSize="sm">
                  Leave no space in front or after the top up value or choose the preset reload amount.
                </Text>
              </VStack>
              <VStack>
                <Text fontSize="lg" bold>
                  How to change password
                </Text>
                <Text textAlign="justify" fontSize="sm">
                  Click 'Profile' and click 'Tukar Katalaluan'. Enter old password thn enter new password twice.
                </Text>
              </VStack>
              <VStack>
                <Text fontSize="lg" bold>
                  How to change profile information
                </Text>
                <Text textAlign="justify" fontSize="sm">
                  Click 'Profile' and click 'Kemaskini Maklumat'. Enter all updated information and no field is left empty.
                </Text>
              </VStack>
              <VStack>
                <Text fontSize="lg" bold>
                  How to create new premis
                </Text>
                <Text textAlign="justify" fontSize="sm">
                  Click 'Kod QR' then click the + icon at the top right. Then enter all the information and 'Hantar'.
                </Text>
                <Text textAlign="justify" fontSize="sm">
                  New premis is added in the selection. can click on the selector below and choose the new premis added.
                </Text>
              </VStack>
            </VStack>
          </ScrollView>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group variant="ghost" space={2}>
            <Button
              onPress={() => {
                props.setShowModal(false);
              }}
            >
              Tutup
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}

export default FAQModal
