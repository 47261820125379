import React, { useState, useEffect } from "react"
import { Image, Platform } from "react-native"
import { MaterialIcons } from "@expo/vector-icons"
import {
  VStack,
  Center,
  Heading,
  Input,
  Button,
  Text,
  View,
  Link,
  Icon,
  useToast,
  FormControl,
  Box,
} from "native-base"
import firebase from "firebase/app"
import "firebase/firestore"
import { clearData, storeLogin, storeUser } from "../../actions/storeUser"
import { connect } from "react-redux"
import base64 from 'react-native-base64'
import loginStyles from "../../stylesheets/login"
import LoadingOverlay from "../../components/LoadingOverlay"
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import Moment from 'moment'

const logo = require("../../assets/MBSP_logo.png")
const logo2 = require("../../assets/banner.png")
const CryptoJS = require("crypto-js")
const key = "GARBAGE"

const LoginScreen = (props) => {
  const [ic_num, setIC] = useState("")
  const [error, setError] = useState({})
  const [password, setPassword] = useState("")
  const [pushToken, setPushToken] = useState("")
  const [expoPushToken, setExpoPushToken] = useState('');
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const database = firebase.firestore() 

  const updateToken = async ( ic ) => {
    try{
      const requestOptions = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify({
          device_id: expoPushToken,
          ic_num: ic,
        }),
      };
      console.log(requestOptions)
      fetch(
        "https://asia-southeast1-i-collect-sws.cloudfunctions.net/updateToken",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
        })
    }catch(err){
      console.log(error)
    }
  }

  const signIn = (ic, pw) => {
    var directory = database.collection("users").doc(ic)
    directory
      .get()
      .then((res) => {
        let decryptPassword = res
          .data()
          .password1.substring(0, res.data().password1.length - 8)
        decryptPassword = CryptoJS.AES.decrypt(decryptPassword, key)
        decryptPassword = decryptPassword.toString(CryptoJS.enc.Utf8)
        if (res.exists) {
          if (pw == decryptPassword) {
            (Platform.OS==="ios" || Platform.OS==="android")&&updateToken(ic)
            toast.show({
              title: "Berjaya Log Masuk.",
              placement: "bottom",
              status: "success",
            })
            props.actions(res.data())
            props.saveCred({ ic_num: ic, password: pw, name: res.data().name })
            setIC("")
            setPassword("")
            props.navigation.navigate("main")
          } else {
            toast.show({
              title: "Salah kata laluan.",
              placement: "bottom",
              status: "warning",
            })
          }
        } else {
          toast.show({
            title: "No. MyKad belum daftar.",
            placement: "bottom",
            status: "warning",
          })
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        toast.show({
          title: "Error: " + error,
          placement: "bottom",
          status: "warning",
        })
      })
  }

  const dataValidation = () => {
    let checkStatus = true
    setError({})
    if (ic_num === "") {
      checkStatus = false
      setError((err) => ({
        ...err,
        ic_num: "Sila masukkan nombor kad pengenalan anda",
      }))
    }
    if (password === "") {
      checkStatus = false
      setError((err) => ({
        ...err,
        password: "Sila masukkan kata laluan anda",
      }))
    }
    if (checkStatus) signIn(ic_num, password)
  }

  const registerForPushNotificationsAsync = async() => {
    let token;
    if (Constants.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
    } else {
      alert('Must use physical device for Push Notifications');
    }
  
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  
    return token;
  }

  const handleTopUpPWA = ( dataToSubmit ) => {
    setLoading(true)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(dataToSubmit),
    }
    fetch(
      "https://asia-southeast1-i-collect-sws.cloudfunctions.net/topUpEventPWA",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data == "success") {
          console.log('success')
          handleTopUp( dataToSubmit.amount )
        } else {
          setLoading(false)
          console.log('fail')
        }
      })
  }

  const handleTopUp = ( amount ) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        name: 'Top Up',
        transaction_amount: Number( amount ),
        date: Moment(new Date()).format( 'MMM DD' ),
        time: Moment(new Date()).format( 'hh:mm A' ),
        ic_num: props.credential.ic_num,
      }),
    }
    fetch(
      "https://asia-southeast1-i-collect-sws.cloudfunctions.net/topUpEvent",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data == "success") {
          setLoading(false)
          signIn(props.credential.ic_num, props.credential.password)
          toast.show({
            title: 'Tambah Nilai Berjaya',
            placement: "bottom",
            status: "success",
          })
        } else {
          handleTopUp()
        }
      })
  }

  useEffect(() => {
    registerForPushNotificationsAsync().then(token => setExpoPushToken(token));
    props.resetRedux()
    if ( Platform.OS !== "android" && Platform.OS !== "ios" ){
      let pathname = window.location.href
      if( pathname.includes('datareturn') ){
        pathname = pathname.substring(pathname.indexOf('=')+1, pathname.length)
        let datareturn=JSON.parse(base64.decode(pathname))
        if( datareturn.status === "SUCCESSFUL" ){
          handleTopUpPWA(datareturn)
        } else {
          toast.show({
            title: 'Tambah Nilai Gagal',
            placement: "bottom",
            status: "danger",
          })
        }
      }else{
        console.log('nodatareturn')
      }
    }
  }, [])

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <VStack space={7} alignItems="center" flex={1} justifyContent="center">
        <VStack space={2}>
          <Center>
            <Image source={logo} style={loginStyles.logo} />
          </Center>
          <Center>
            <Box backgroundColor="white" borderRadius={20} p={2}>
              <Image source={logo2} style={loginStyles.logo2} />
            </Box>
          </Center>
          <Center>
            <Heading fontSize="2xl" style={loginStyles.title}>
              Sistem i-Collect MBSP
            </Heading>
          </Center>
        </VStack>
        <Center>
          <View>
            {!(props.credential.ic_num === "") &&
              !(props.credential.password === "") && (
                <Button
                  style={loginStyles.bufferedLoginBtn}
                  variant="outline"
                  colorScheme="light"
                  onPress={() => {
                    setLoading(true)
                    signIn(props.credential.ic_num, props.credential.password)
                  }}
                >
                  <Text
                    bold
                    fontSize="sm"
                    style={loginStyles.bufferedLoginText}
                  >
                    Masuk Sebagai
                  </Text>
                  <Text
                    bold
                    fontSize="sm"
                    style={loginStyles.bufferedLoginBtnText}
                  >
                    {props.credential.name}
                  </Text>
                </Button>
              )}
          </View>
        </Center>
        <VStack space={3} style={loginStyles.loginContainer}>
          <Text bold fontSize="lg" style={loginStyles.subtitle}>
            Log Masuk
          </Text>
          <FormControl isInvalid={"ic_num" in error}>
            <Input
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="badge" />}
                  size="md"
                  m={2}
                  _light={{
                    color: "gray.500",
                  }}
                  _dark={{
                    color: "gray.500",
                  }}
                />
              }
              variant="filled"
              placeholder="No. MyKad"
              onChangeText={(value) => setIC(value.trim())}
            />
            <FormControl.ErrorMessage
              mb={1}
              mt={0}
              _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
            >
              {error.ic_num}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={"password" in error}>
            <Input
              type="password"
              InputLeftElement={
                <Icon
                  as={<MaterialIcons name="lock" />}
                  size="md"
                  m={2}
                  _light={{
                    color: "gray.500",
                  }}
                  _dark={{
                    color: "gray.500",
                  }}
                />
              }
              variant="filled"
              placeholder="Kata Laluan"
              onChangeText={(value) => setPassword(value)}
            />
            <FormControl.ErrorMessage
              mb={1}
              mt={0}
              _text={{ fontSize: "xs", color: "error.500", fontWeight: 500 }}
            >
              {error.password}
            </FormControl.ErrorMessage>
          </FormControl>
          <Link
            alignItems="flex-end"
            isUnderlined
            _text={{ color: "white" }}
            onPress={() => props.navigation.navigate("forget")}
          >
            Lupa KataLaluan?
          </Link>
          <Button
            colorScheme="teal"
            onPress={() => {
              setLoading(true)
              dataValidation()
            }}
          >
            Log Masuk
          </Button>
          <Button
            colorScheme="red"
            _text={{ color: "white" }}
            onPress={() => props.navigation.navigate("register")}
          >
            Daftar
          </Button>
        </VStack>
      </VStack>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
  credential: state.CredReducer.credentials,
})

const mapDispatchToProps = (dispatch) => ({
  actions: (val) => {
    dispatch(storeUser(val))
  },
  saveCred: (val) => {
    dispatch(storeLogin(val))
  },
  resetRedux: () => {
    dispatch(clearData())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
