import {
  Text,
  Input,
  Button,
  VStack,
  FormControl,
  IconButton,
  Icon,
  useToast,
  Center,
  Box,
} from "native-base";
import React, { useState, useEffect } from "react";
import { MaterialIcons } from "@expo/vector-icons";
import LoadingOverlay from "../../../components/LoadingOverlay";

import TopNav from "../../../components/TopNav";

const EnterNewPassword = (props) => {
  const [error, setError] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password, setPassword] = useState({
    password1: "",
    password2: "",
  });
  const toast = useToast();

  
  const recoverPassword = async () =>{
    setLoading(true)
    const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
       },
      body: JSON.stringify({
        ic_num: props.route.params.ic_num,
        code: props.route.params.code,
        password1: password.password1
      }),
    };
    fetch("https://asia-southeast1-i-collect-sws.cloudfunctions.net/newPassword", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if(data == "Berjaya pemulih kata laluan."){
          toast.show({
            title: data,
            placement: "bottom",
            status: "success",
          });
          props.navigation.navigate('login')
        }else{
          toast.show({
            title: data,
            placement: "bottom",
            status: "danger",
          });
        }
      });
  }


  const dataValidation = () => {
    let checkStatus = true;
    setError({});
    Object.keys(password).map((val) => {
      if (password[val] === "") {
        checkStatus = false;
        setError((error) => ({ ...error, [val]: "This field is required" }));
      } else {
        if (password[val].length < 8) {
          checkStatus = false;
          setError((error) => ({
            ...error,
            [val]: "Password length must not be lesser than 8",
          }));
        }
        if (password["password1"] != password["password2"]) {
          checkStatus = false;
          setError((error) => ({
            ...error,
            [val]: "Password is not same as the confirmation password",
          }));
        }
      }
    });
    if (checkStatus) {
      recoverPassword()
    }
  };

  return (
    <>
      <TopNav rightComponent="close" navigation={props.navigation}/>
      {isLoading&&<LoadingOverlay/>}
      <Center flex={1}>
        <Box width={"90%"} borderRadius={20} backgroundColor="white" p={5}>
          <VStack space={2}>
            <Text bold fontSize="lg">
              Tetap Semula Katalaluan
            </Text>
            <Text>Sila masukkan katalaluan yang baharu.</Text>
            <FormControl isInvalid={"password1" in error}>
              <Input
                placeholder="Kata Laluan"
                type={showPassword1 ? "text" : "password"}
                value={password.password1}
                onChangeText={(value) =>
                  setPassword({ ...password, password1: value })
                }
                InputRightElement={
                  <IconButton
                    icon={
                      <Icon
                        as={
                          <MaterialIcons
                            name={
                              showPassword1 ? "visibility-off" : "visibility"
                            }
                          />
                        }
                      />
                    }
                    onPress={() => setShowPassword1(!showPassword1)}
                  />
                }
              />
              {"password1" in error ? (
                <FormControl.ErrorMessage
                  mb={1}
                  mt={0}
                  _text={{
                    fontSize: "xs",
                    color: "error.500",
                    fontWeight: 500,
                  }}
                >
                  {error.password1}
                </FormControl.ErrorMessage>
              ) : (
                <></>
              )}
            </FormControl>
            <FormControl isInvalid={"password2" in error}>
              <Input
                placeholder="Ulang Kata Laluan"
                type={showPassword2 ? "text" : "password"}
                value={password.password2}
                onChangeText={(value) =>
                  setPassword({ ...password, password2: value })
                }
                InputRightElement={
                  <IconButton
                    icon={
                      <Icon
                        as={
                          <MaterialIcons
                            name={
                              showPassword2 ? "visibility-off" : "visibility"
                            }
                          />
                        }
                      />
                    }
                    onPress={() => setShowPassword2(!showPassword2)}
                  />
                }
              />
              {"password2" in error ? (
                <FormControl.ErrorMessage
                  mb={1}
                  mt={0}
                  _text={{
                    fontSize: "xs",
                    color: "error.500",
                    fontWeight: 500,
                  }}
                >
                  {error.password2}
                </FormControl.ErrorMessage>
              ) : (
                <></>
              )}
            </FormControl>
            <Button onPress={dataValidation} colorScheme="teal">Hantar</Button>
          </VStack>
        </Box>
      </Center>
    </>
  );
};

export default EnterNewPassword;
