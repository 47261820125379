import {
  Text,
  Input,
  Button,
  VStack,
  FormControl,
  IconButton,
  Icon,
  useToast,
  Center,
  Box,
} from "native-base";
import React, { useState } from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { compose } from "redux";
import { connect } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay";
import firebase from "firebase/app";
import "firebase/firestore";
import { storeUser, storeLogin } from "../../../actions/storeUser";

const ChangePassword = (props) => {
  const [error, setError] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showOriPassword, setShowOriPassword] = useState(false);
  const [password, setPassword] = useState({
    oriPassword: "",
    password1: "",
    password2: "",
  });
  const toast = useToast();
  const CryptoJS = require("crypto-js");
  const key = "GARBAGE";
  const database = firebase.firestore();

  const changePassword = () => {
    const requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify({
        ic_num: props.user.ic_num,
        oriPassword: password.oriPassword,
        password1: password.password1,
      }),
    };
    console.log(requestOptions)
    fetch(
      "https://asia-southeast1-i-collect-sws.cloudfunctions.net/changePassword",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data == "Kemaskini Berjaya.") {
          props.saveCred({
            ic_num: props.user.ic_num,
            password: password.password1,
          });
          props.navigation.navigate("profile");
          toast.show({
            title: data,
            placement: "bottom",
            status: "success",
          });
        } else {
          toast.show({
            title: data,
            placement: "bottom",
            status: "danger",
          });
        }
      });
  };

  const dataValidation = () => {
    let checkStatus = true;
    setError({});
    Object.keys(password).map((val) => {
      if (password[val] === "") {
        checkStatus = false;
        setError((error) => ({ ...error, [val]: "This field is required" }));
      } else {
        if (password[val].length < 8) {
          checkStatus = false;
          setError((error) => ({
            ...error,
            [val]: "Password length must not be lesser than 8",
          }));
        }
        if (password["password1"] != password["password2"]) {
          checkStatus = false;
          setError((error) => ({
            ...error,
            [val]: "Password is not same as the confirmation password",
          }));
        }
      }
    });
    if (checkStatus) {
      setLoading(true);
      changePassword();
    }
  };

  return (
    <>
      <Center flex={1}>
        {isLoading && <LoadingOverlay />}
        <Box width={"90%"} borderRadius={20} backgroundColor="white" p={5}>
          <VStack space={2}>
            <Text bold fontSize="2xl">
              Tukar Katalaluan
            </Text>
            <FormControl isInvalid={"oriPassword" in error}>
              <Input
                placeholder="Kata Laluan Lama"
                type={showOriPassword ? "text" : "password"}
                value={password.oriPassword}
                onChangeText={(value) =>
                  setPassword({ ...password, oriPassword: value })
                }
                InputRightElement={
                  <IconButton
                    icon={
                      <Icon
                        as={
                          <MaterialIcons
                            name={
                              showOriPassword ? "visibility-off" : "visibility"
                            }
                          />
                        }
                      />
                    }
                    onPress={() => setShowOriPassword(!showOriPassword)}
                  />
                }
              />
              {"oriPassword" in error ? (
                <FormControl.ErrorMessage
                  mb={1}
                  mt={0}
                  _text={{
                    fontSize: "xs",
                    color: "error.500",
                    fontWeight: 500,
                  }}
                >
                  {error.oriPassword}
                </FormControl.ErrorMessage>
              ) : (
                <></>
              )}
            </FormControl>
            <FormControl isInvalid={"password1" in error}>
              <Input
                placeholder="Kata Laluan Baharu"
                type={showPassword1 ? "text" : "password"}
                value={password.password1}
                onChangeText={(value) =>
                  setPassword({ ...password, password1: value })
                }
                InputRightElement={
                  <IconButton
                    icon={
                      <Icon
                        as={
                          <MaterialIcons
                            name={
                              showPassword1 ? "visibility-off" : "visibility"
                            }
                          />
                        }
                      />
                    }
                    onPress={() => setShowPassword1(!showPassword1)}
                  />
                }
              />
              {"password1" in error ? (
                <FormControl.ErrorMessage
                  mb={1}
                  mt={0}
                  _text={{
                    fontSize: "xs",
                    color: "error.500",
                    fontWeight: 500,
                  }}
                >
                  {error.password1}
                </FormControl.ErrorMessage>
              ) : (
                <></>
              )}
            </FormControl>
            <FormControl isInvalid={"password2" in error}>
              <Input
                placeholder="Ulang Kata Laluan"
                type={showPassword2 ? "text" : "password"}
                value={password.password2}
                onChangeText={(value) =>
                  setPassword({ ...password, password2: value })
                }
                InputRightElement={
                  <IconButton
                    icon={
                      <Icon
                        as={
                          <MaterialIcons
                            name={
                              showPassword2 ? "visibility-off" : "visibility"
                            }
                          />
                        }
                      />
                    }
                    onPress={() => setShowPassword2(!showPassword2)}
                  />
                }
              />
              {"password2" in error ? (
                <FormControl.ErrorMessage
                  mb={1}
                  mt={0}
                  _text={{
                    fontSize: "xs",
                    color: "error.500",
                    fontWeight: 500,
                  }}
                >
                  {error.password2}
                </FormControl.ErrorMessage>
              ) : (
                <></>
              )}
            </FormControl>
            <Button onPress={dataValidation}>Hantar</Button>
            <Button
              colorScheme="orange"
              onPress={() => props.navigation.navigate("profile")}
            >
              <Text color="white">Batal</Text>
            </Button>
          </VStack>
        </Box>
      </Center>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
  actions: (val) => {
    dispatch(storeUser(val));
  },
  saveCred: (val) => {
    dispatch(storeLogin(val));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
