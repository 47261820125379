import { Text, Input, Button, VStack, Center, Box, useToast } from "native-base"
import React, { useState } from "react"
import TopNav from "../../../components/TopNav"
import LoadingOverlay from "../../../components/LoadingOverlay"

const EnterEmail = (props) => {
  const [ic_num, setIC] = useState("")
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()

  const sendEmail = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ ic_num: ic_num }),
    }
    fetch(
      "https://asia-southeast1-i-collect-sws.cloudfunctions.net/sendRecover",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false)
        if (data == "E-mel sudah hantar.") {
          toast.show({
            title: data,
            placement: "bottom",
            status: "success",
          })
          props.navigation.navigate("code", { ic_num: ic_num })
        } else {
          toast.show({
            title: data,
            placement: "bottom",
            status: "danger",
          })
        }
      })
  }

  return (
    <>
      <TopNav rightComponent="close" navigation={props.navigation} />
      {isLoading && <LoadingOverlay />}
      <Center flex={1}>
        <Box width={"90%"} borderRadius={20} backgroundColor="white" p={5}>
          <VStack space={2}>
            <Text bold fontSize="lg">
              Terlupa Katalaluan?
            </Text>
            <Text>Sila masukkan No. MyKad anda.</Text>
            <Input
              variant="underlined"
              placeholder="No. MyKad"
              onChangeText={(val) => {
                setIC(val)
              }}
            />
            <Button
              onPress={() => {
                setLoading(true)
                sendEmail()
              }}
              colorScheme="teal"
            >
              Hantar
            </Button>
          </VStack>
        </Box>
      </Center>
    </>
  )
}

export default EnterEmail
