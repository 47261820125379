import { StyleSheet } from 'react-native';
import { borderWidth } from 'styled-system';

const loginStyles = StyleSheet.create({
  logo:{
    width: 60,
    height: 60,
    resizeMode: 'contain'
  },
  logo2:{
    width: 200,
    height: 80,
    resizeMode: 'contain'
  },
  title:{
    textAlign: 'center',  
    color: "#ffffff"
  },
  bufferedLoginBtn:{
    borderRadius: 100,
    borderColor: '#3BFC8D',
  },
  bufferedLoginText:{
    color:'white',
    textAlign: 'center',
    paddingStart: 20,
    paddingEnd: 20
  },
  bufferedLoginBtnText:{
    color:'#3BFC8D',
    textAlign: 'center',
    paddingStart: 20,
    paddingEnd: 20
  },
  loginContainer:{
    width: '80%'
  },
  subtitle:{
    color: '#ffffff'
  }
})

export default loginStyles;