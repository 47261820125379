export const getUser = (response) => {
  return (dispatch) => {
    dispatch(storeUser(response));
  };
};

export const getEvent = (response) => {
  return (dispatch) => {
    dispatch(storeEvent(response));
  };
};

export const resetData = () => {
  return (dispatch) => {
    dispatch(clearData());
  };
};

export const getLogin = (response) => {
  return (dispatch) => {
    dispatch(storeLogin(response));
  };
};

export const storeUser = (data) => ({
  type: "STORE_USER",
  payload: data,
});

export const storeEvent = (data) => ({
  type: "STORE_EVENT",
  payload: data,
});

export const clearData = () => ({
  type: "CLEAR_DATA"
});

export const storeLogin = (data) => ({
  type: "STORE_LOGIN",
  payload: data,
});
