import React from "react"
var QRCode = require("qrcode.react")

const QRpwa = ({value}) => {
  return (
    <QRCode
      value={value}
      size={225}
      includeMargin={true}
    />
  )
}

export default QRpwa
