import {
  VStack,
  Box,
  HStack,
  Text,
  Button,
  ScrollView,
  Input,
  useToast,
  FormControl,
  Select,
} from "native-base"
import firebase from "firebase/app"
import "firebase/firestore"
import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Animated, RefreshControl, Dimensions, Platform } from "react-native"

import { storeUser, storeLogin } from "../../../actions/storeUser"
import TopNav from "../../../components/TopNav"
import LoadingOverlay from "../../../components/LoadingOverlay"
import TabMenu from "../../../components/TabMenu"

const UserProfile = (props) => {
  const [user, setUser] = useState({})
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState({})
  const [isLoading, setLoading] = useState(false)
  const toast = useToast()
  const visibility = useRef(new Animated.Value(0)).current
  const [refreshing, setRefreshing] = useState(false)
  const database = firebase.firestore()

  let businessType = {
    makanan: "Kedai makanan/Restoran makanan",
    runcit: "Kedai Runcit/Serbaneka",
    minyak: "Stesen Minyak",
    lain: "Lain-lain",
  }

  const refreshInfo = () => {
    setRefreshing(true)
    var directory = database.collection("users").doc(user.ic_num)
    directory
      .get()
      .then((res) => {
        props.actions(res.data())
        setRefreshing(false)
      })
      .catch((error) => {
        setRefreshing(false)
        toast.show({
          title: "Error: " + error,
          placement: "bottom",
          status: "warning",
        })
      })
  }

  const validateName = (name) => {
    let isValidName = true
    if (/[!@#$%^&*(),.?":{}|<>]/g.test(name) || /\d+/g.test(name)) {
      isValidName = false
    }
    return isValidName
  }

  const updateUserData = () => {
    setLoading(true)
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        name: user.name,
        address: user.address,
        shop_name: user.shop_name,
        poscode: user.poscode,
        business_type: user.business_type,
        phone_num: user.phone_num,
        email: user.email,
        ic_num: user.ic_num,
      }),
    }
    fetch(
      "https://asia-southeast1-i-collect-sws.cloudfunctions.net/updateDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false)
        if (data == "Kemaskini Berjaya.") {
          setEdit(false)
          toast.show({
            title: data,
            placement: "bottom",
            status: "success",
          })
        } else {
          toast.show({
            title: data,
            placement: "bottom",
            status: "danger",
          })
        }
      })
  }

  const dataValidation = () => {
    let checkStatus = true
    let checkItem = [
      "name",
      "ic_num",
      "shop_name",
      "address",
      "poscode",
      "business_type",
      "phone_num",
      "email",
    ]
    setError({})
    Object.keys(user).map((val) => {
      if (checkItem.includes(val))
        if (user[val] === "") {
          checkStatus = false
          if (val === "business_type")
            setError((error) => ({
              ...error,
              [val]: "Please select a business type from the list",
            }))
          else
            setError((error) => ({ ...error, [val]: "This field is required" }))
        } else {
          if (typeof user[val] === "string")
            setUser((prev) => ({ ...prev, [val]: user[val].trim() }))
          switch (val) {
            case "name":
              if (!validateName(user[val])) {
                checkStatus = false
                setError((error) => ({
                  ...error,
                  [val]:
                    "Please provide valid name which only contains alphabetical values",
                }))
              }
              break
            case "ic_num":
              let ic_temp = user[val].replace(/-/g, "")
              if (ic_temp.length < 12 || ic_temp.length > 12) {
                checkStatus = false
                setError((error) => ({
                  ...error,
                  [val]:
                    "Please provide valid IC Number. \n(Eg. XXXXXX-XX-XXXX or XXXXXXXXXXXX)",
                }))
              }
              break
            case "phone_num":
              let phone_temp = user[val].replace(/-/g, "")
              if (phone_temp.length < 10 || phone_temp.length > 10) {
                checkStatus = false
                setError((error) => ({
                  ...error,
                  [val]:
                    "Please provide valid Phone Number. \n(Eg. XXX-XXXXXXX or XXXXXXXXXX)",
                }))
              }
              break
            case "poscode":
              if (
                isNaN(user[val]) ||
                user[val].length < 5 ||
                user[val].length > 5
              ) {
                checkStatus = false
                setError((error) => ({
                  ...error,
                  [val]: "Please provide valid Poscode",
                }))
              }
              break
            case "shop_name":
              if (!validateName(user[val])) {
                checkStatus = false
                setError((error) => ({
                  ...error,
                  [val]:
                    "Please provide valid name which only contains alphabetical values",
                }))
              }
              break
            default:
          }
        }
    })
    console.log(error)
    if (checkStatus) {
      updateUserData()
      props.actions(user)
    }
  }

  const initializeUser = () => {
    if (props.user.shop_name) setUser({ ...props.user })
    else setUser({ ...props.user, shop_name: "" })
  }

  useEffect(() => {
    initializeUser()
  }, [])

  return (
    <>
      <TopNav rightComponent="notification" navigation={props.navigation} />
      {isLoading && <LoadingOverlay />}
      <Box
        w={Platform.OS === "web" ? "100vw" : "100%"}
        height={Platform.OS === "web" ? "82vh" : "82%"}
      >
        <ScrollView
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={refreshInfo} />
          }
        >
          <VStack alignItems="center" space={5}>
            <Box width={"90%"} borderRadius={20} backgroundColor="white">
              <VStack alignItems="center" space={5} mx={5} my={4}>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Nama
                  </Text>
                  {edit ? (
                    <FormControl isInvalid={"name" in error} flex={2}>
                      <Input
                        value={user.name}
                        fontSize="sm"
                        onChangeText={(value) =>
                          setUser({ ...user, name: value })
                        }
                      />
                      {"name" in error && (
                        <FormControl.ErrorMessage
                          mb={1}
                          mt={0}
                          _text={{
                            fontSize: "xs",
                            color: "error.500",
                            fontWeight: 500,
                          }}
                        >
                          {error.name}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>
                  ) : (
                    <Text flex={2} fontSize="sm" bold>
                      {user.name}
                    </Text>
                  )}
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Kad Pengenalan
                  </Text>
                  {edit ? (
                    <FormControl isInvalid={"ic_num" in error} flex={2}>
                      <Input
                        value={user.ic_num}
                        fontSize="sm"
                        isDisabled={true}
                      />
                      {"ic_num" in error && (
                        <FormControl.ErrorMessage
                          mb={1}
                          mt={0}
                          _text={{
                            fontSize: "xs",
                            color: "error.500",
                            fontWeight: 500,
                          }}
                        >
                          {error.ic_num}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>
                  ) : (
                    <Text flex={2} fontSize="sm" bold>
                      {user.ic_num}
                    </Text>
                  )}
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Nama Kedai
                  </Text>
                  {edit ? (
                    <FormControl isInvalid={"shop_name" in error} flex={2}>
                      <Input
                        value={user.shop_name}
                        fontSize="sm"
                        onChangeText={(value) =>
                          setUser({ ...user, shop_name: value })
                        }
                      />
                      {"shop_name" in error && (
                        <FormControl.ErrorMessage
                          mb={1}
                          mt={0}
                          _text={{
                            fontSize: "xs",
                            color: "error.500",
                            fontWeight: 500,
                          }}
                        >
                          {error.shop_name}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>
                  ) : (
                    <Text
                      flex={2}
                      fontSize="sm"
                      bold={user.shop_name}
                      style={{ color: user.shop_name ? "black" : "grey" }}
                    >
                      {user.shop_name ? user.shop_name : "Tidak ditetapkan"}
                    </Text>
                  )}
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Alamat
                  </Text>
                  {edit ? (
                    <FormControl isInvalid={"address" in error} flex={2}>
                      <Input
                        value={user.address}
                        fontSize="sm"
                        onChangeText={(value) =>
                          setUser({ ...user, address: value })
                        }
                      />
                      {"address" in error && (
                        <FormControl.ErrorMessage
                          mb={1}
                          mt={0}
                          _text={{
                            fontSize: "xs",
                            color: "error.500",
                            fontWeight: 500,
                          }}
                        >
                          {error.address}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>
                  ) : (
                    <Text flex={2} fontSize="sm" bold>
                      {user.address}
                    </Text>
                  )}
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Poskod
                  </Text>
                  {edit ? (
                    <FormControl isInvalid={"poscode" in error} flex={2}>
                      <Input
                        value={user.poscode}
                        fontSize="sm"
                        onChangeText={(value) =>
                          setUser({ ...user, poscode: value })
                        }
                      />
                      {"poscode" in error && (
                        <FormControl.ErrorMessage
                          mb={1}
                          mt={0}
                          _text={{
                            fontSize: "xs",
                            color: "error.500",
                            fontWeight: 500,
                          }}
                        >
                          {error.poscode}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>
                  ) : (
                    <Text flex={2} fontSize="sm" bold>
                      {user.poscode}
                    </Text>
                  )}
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    Jenis Perniagaan
                  </Text>
                  {edit ? (
                    <FormControl isInvalid={"business_type" in error} flex={2}>
                      <Select
                        selectedValue={user.business_type}
                        fontSize="xxs"
                        onValueChange={(value) =>
                          setUser({ ...user, business_type: value })
                        }
                      >
                        <Select.Item
                          label="Kedai makanan/Restoran makanan"
                          value="makanan"
                        />
                        <Select.Item
                          label="Kedai Runcit/Serbaneka"
                          value="runcit"
                        />
                        <Select.Item label="Stesen Minyak" value="minyak" />
                        <Select.Item label="Lain-lain" value="lain" />
                      </Select>
                      {"business_type" in error ? (
                        <FormControl.ErrorMessage
                          mb={1}
                          mt={0}
                          _text={{
                            fontSize: "xs",
                            color: "error.500",
                            fontWeight: 500,
                          }}
                        >
                          {error.business_type}
                        </FormControl.ErrorMessage>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  ) : (
                    <Text flex={2} fontSize="sm" bold>
                      {businessType[user.business_type]}
                    </Text>
                  )}
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    No. Telefon
                  </Text>
                  {edit ? (
                    <FormControl isInvalid={"phone_num" in error} flex={2}>
                      <Input
                        value={user.phone_num}
                        fontSize="sm"
                        onChangeText={(value) =>
                          setUser({ ...user, phone_num: value })
                        }
                      />
                      {"phone_num" in error && (
                        <FormControl.ErrorMessage
                          mb={1}
                          mt={0}
                          _text={{
                            fontSize: "xs",
                            color: "error.500",
                            fontWeight: 500,
                          }}
                        >
                          {error.phone_num}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>
                  ) : (
                    <Text flex={2} fontSize="sm" bold>
                      {user.phone_num}
                    </Text>
                  )}
                </HStack>
                <HStack alignItems="center" width={"100%"}>
                  <Text flex={1} color="grey" fontSize="sm">
                    E-mel
                  </Text>
                  {edit ? (
                    <FormControl isInvalid={"email" in error} flex={2}>
                      <Input
                        value={user.email}
                        fontSize="sm"
                        onChangeText={(value) =>
                          setUser({ ...user, email: value })
                        }
                      />
                      {"email" in error && (
                        <FormControl.ErrorMessage
                          mb={1}
                          mt={0}
                          _text={{
                            fontSize: "xs",
                            color: "error.500",
                            fontWeight: 500,
                          }}
                        >
                          {error.email}
                        </FormControl.ErrorMessage>
                      )}
                    </FormControl>
                  ) : (
                    <Text flex={2} fontSize="sm" bold>
                      {user.email}
                    </Text>
                  )}
                </HStack>
              </VStack>
            </Box>
            <Box width={"90%"}>
              <VStack space={2}>
                <Box backgroundColor="white" borderRadius={10}>
                  <Button
                    variant="outline"
                    borderWidth={2}
                    borderRadius={10}
                    onPress={() => {
                      if (edit) dataValidation()
                      else setEdit(true)
                    }}
                  >
                    {edit ? "Simpan" : "Kemaskini Maklumat"}
                  </Button>
                </Box>
                {!edit && (
                  <Box backgroundColor="white" borderRadius={10}>
                    <Button
                      variant="outline"
                      borderWidth={2}
                      borderRadius={10}
                      onPress={() =>
                        props.navigation.navigate("changePassword")
                      }
                    >
                      Tukar Katalaluan
                    </Button>
                  </Box>
                )}
                {!edit && (
                  <Box backgroundColor="white" borderRadius={10}>
                    <Button
                      onPress={() => {
                        props.saveCred({ ic_num: "", password: "", name: "" })
                        props.navigation.navigate("login")
                      }}
                      colorScheme="pink"
                      variant="outline"
                      borderWidth={2}
                      borderRadius={10}
                    >
                      Log Keluar
                    </Button>
                  </Box>
                )}
                {edit && (
                  <Box backgroundColor="white" borderRadius={10}>
                    <Button
                      onPress={() => {
                        initializeUser()
                        setError({})
                        setEdit(false)
                      }}
                      colorScheme="pink"
                      variant="outline"
                      borderWidth={2}
                      borderRadius={10}
                    >
                      Batalkan
                    </Button>
                  </Box>
                )}
              </VStack>
            </Box>
          </VStack>
        </ScrollView>
      </Box>
      {/* <TabMenu navigation={props.navigation} activePage={props.navigation.isFocused?"profile":""}/> */}
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.userReducer.users,
  transactions: state.userReducer.events,
})

const mapDispatchToProps = (dispatch) => ({
  actions: (val) => {
    dispatch(storeUser(val))
  },
  saveCred: (val) => {
    dispatch(storeLogin(val))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
