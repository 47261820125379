import { StyleSheet } from "react-native";

const TopNavStyles = StyleSheet.create({
  logo: {
    width: 40,
    height: 40,
    resizeMode: "contain",
  }
});

export default TopNavStyles;
